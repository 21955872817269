<template>
  <div class="total-container bg-secondaryColor">
    <div
      v-if="roles.length === 0"
      class="flex flex-1 justify-center items-center h-100vh"
    >
      <Loader />
    </div>
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <div
          v-if="roles.length !== 0"
          id="layout"
          class="flex w-full flex-row mobile:flex-col mobile:block"
        >
          <NavigationMobile :roles="roles" class="desktop:hidden" />
          <Navigation
            class="mobile:hidden desktop:flex"
            :roles="roles"
            :clicked="showPersonalData"
          />
          <div
            class="overflow-y-auto flex flex-auto flex-col app-content desktop:h-100"
          >
            <div
              class="h-topNavHeight mt-4 flex justify-between items-center bg-transparent mobile:bg-transparent px-10 mobile:px-1"
            >
              <div class="flex flex-row my-5">
                <font-awesome-icon
                  v-if="$route.name !== 'home' && $route.name !== 'play'"
                  icon="chevron-left"
                  class="text-standardBgColor fa-lg self-center mr-5 cursor-pointer"
                  @click="$router.back()"
                />
                <div class="flex flex-col">
                  <!-- <p
                  class="text-breadCrumbText text-breadCrumb font-medium leading-none mb-1"
                >
                  <span
                    v-for="item in $route.path
                      .replace(/^\/|\/$/g, '')
                      .split('/')"
                    :key="item"
                  >
                    {{
                      item === ""
                        ? $t("breadcrumbs.home")
                        : isNaN(item)
                        ? item =  $t(`breadcrumbs.${item}`)
                        : $t(`${item}`)
                    }}
                    /
                  </span>
                </p> -->
                  <p
                    v-if="$route.name !== 'play'"
                    class="text-lightAlineaColor text-title font-bold leading-none"
                  >
                    {{ $t(`breadcrumbs.${$route.name}`) }}
                  </p>
                </div>
              </div>
              <div class="flex mobile:hidden">
                <select
                  class="block appearance-none px-badgeLR py-badgeLR text-secondaryColor text-badge font-medium leading-none w-full rounded leading-tight"
                  v-model="$i18n.locale"
                  data-cy="language"
                  @input="setLocale"
                >
                  <option
                    v-for="(lang, i) in langs"
                    :key="`Lang${i}`"
                    :value="lang"
                  >
                    {{ lang }}
                  </option>
                </select>
              </div>
            </div>
            <component class="z-10" :is="Component" />
          </div>
          <ActivationCodeModal
            v-if="showActivationModal"
            @cancel="toggleActivationModal"
          />
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Loader from "./components/helpers/Loader";
import { mapState } from "vuex";
import Navigation from "./components/navigation/Navigation";
import NavigationMobile from "./components/navigation/NavigationMobile";
import ActivationCodeModal from "./components/helpers/modals/ActivationCodeModal";
import { RepositoryFactory } from "./repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import { setLocale } from "@vee-validate/i18n";

export default {
  name: "Dashboard",
  components: {
    Navigation,
    NavigationMobile,
    ActivationCodeModal,
    Loader,
  },
  data() {
    return {
      roles: [],
      showResetModal: false,
      showPersonalData: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      langs: ["nl", "en"],
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser", "showActivationModal"]),
    ...mapState("trainingStore", ["trainings", "loading"]),
  },
  watch: {
    currentUser: {
      handler(val) {
        if (val.roles !== undefined) {
          this.roles = val.roles;
          this.fetch();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.dispatch("userStore/GET_CURRENT_USER");
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("trainingStore/GET_TRAININGS");
    },
    toggleActivationModal() {
      this.$store.dispatch("userStore/TOGGLE_ACTIVATION_MODAL");
    },
    setLocale(e) {
      localStorage.setItem("locale", e.target.value);
      setLocale(e.target.value);
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  height: 100vh;
}

.total-container {
  height: inherit;
}

body {
  width: 100%;
}

.content {
  width: calc(100% - 200px);
}

#nav {
  min-width: 200px;
  height: 100vh;
}

#layout {
  height: inherit;
}

.app-content {
  height: calc(100% - 60px);
}
</style>
