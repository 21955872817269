<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        v-for="(val, index) in newValue"
        :key="`stringarray-${title}-${index}`"
        class="flex justify-between items-center w-full mt-1"
      >
        <AutoComplete
          v-if="constraintType === 'Choice'"
          v-model="newValue[index]"
          v-tooltip="{
            content: `<div class='flex flex-col z-100'>
                    <span>${newValue[index]}</span>
                  </div>`,
            boundary: 'window',
            html: true,
          }"
          dropdown
          dropdown-class="ml-[-2.4rem] custom-dropdown"
          force-selection
          :suggestions="suggestions"
          @complete="search"
          @change="updateValue"
          @focus="disableShortcuts(true)"
          @blur="disableShortcuts(false)"
        >
          <template #option="suggestion">
            <div class="flex items-center">
              <div class="text-wrap break-all">{{ suggestion.option }}</div>
            </div>
          </template>
        </AutoComplete>
        <InputField
          v-else
          :title="`${title} ${index + 1}`"
          class="w-full flex items-center"
          :active-color="activeColor"
          :show-title="false"
          v-model="newValue[index]"
          rules="required|min:1|max:255"
        />
        <font-awesome-icon
          icon="clipboard"
          class="text-white w-5 h-5 outline-none ml-2 cursor-pointer hover:text-blue-500"
          @click="copyValue(newValue[index])"
        />
        <div v-if="newValue.length > 1" class="ml-2">
          <font-awesome-icon
            icon="trash"
            class="text-red-500 text-16 rotate m-auto"
            @click="deleteItem(index, newValue[index])"
          />
        </div>
      </div>
      <div v-if="showCopied">
        <p class="text-sm text-green-500 mt-1">
          {{ $t("editor.copied_to_clipboard") }}
        </p>
      </div>
      <div
        v-if="newValue[newValue.length - 1] !== ''"
        @click="addOption"
        class="flex items-center mt-2"
      >
        <p class="text-14 text-white mt-px mr-2">
          {{ $t("editor.add_item") }}
        </p>
        <font-awesome-icon icon="plus" class="text-white text-14 rotate" />
      </div>
      <div class="h-6 mt-px"></div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errors.length" class="text-sm text-red-600">
        {{ errors[0] }}
      </p>
    </div>
  </div>
</template>

<script>
import InputField from "@/views/editor/components/inputs/InputField";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { disableShortcuts } from "@/services/disableShortcutsService";
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    InputField,
    ErrorPopup,
    AutoComplete,
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    width: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    constraintProperties: {
      type: Object,
      default: () => {},
    },
    constraintType: {
      type: String,
      default: null,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newValue: [],
      errors: [],
      suggestions: this.options ? [...this.options] : [],
      showCopied: false,
    };
  },
  watch: {
    newValue: {
      handler() {
        this.updateValue();
      },
      deep: true,
    },
    modelValue: {
      handler(val) {
        this.newValue = val;
      },
    },
  },
  mounted() {
    if (this.requiredInput) {
      this.newValue = this.modelValue.length
        ? this.modelValue
        : this.defaultValue;
    } else {
      this.newValue = this.modelValue;
    }
  },
  methods: {
    addOption() {
      if (this.constraintType === "Choice") {
        const notYetSelected = this.options.filter(
          (word) => !this.newValue.includes(word),
        );
        this.newValue.push(notYetSelected[0]);
      } else {
        this.newValue.push("");
      }
    },
    deleteItem(index) {
      this.newValue.splice(index, 1);
    },
    updateValue() {
      this.$emit("update:modelValue", this.newValue);
    },
    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
    search(event) {
      this.suggestions = [...this.options].filter(
        (element) =>
          element?.toLowerCase().indexOf(event.query.toLowerCase()) !== -1,
      );
    },
    copyValue(value) {
      const clipboardData = window.clipboardData || navigator.clipboard;
      clipboardData.writeText(value);
      this.showCopied = true;
      setTimeout(() => (this.showCopied = false), 1500);
    },
  },
};
</script>
