import ApiV4Repository from "@/repository/ApiV4Repository";

const resource = "/api/v4/text-to-speech";

export default {
  getVoices() {
    return ApiV4Repository.get(`${resource}/voices`);
  },
  synthesizeToUrl(payload) {
    return ApiV4Repository.post(`${resource}/synthesize`, payload);
  },
  regenerateTts(level_id) {
    return ApiV4Repository.patch(`${resource}/regenerate-level/${level_id}`);
  },
};
