<template>
  <div
    v-if="cardShow"
    class="relative z-50"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
        >
          <div class="pointer-events-auto w-screen max-w-md">
            <div
              class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
            >
              <div class="relative mt-6 flex-1 px-4 sm:px-6">
                <div class="fixed inset-0 overflow-hidden">
                  <div class="absolute inset-0 overflow-hidden">
                    <section
                      class="absolute inset-y-0 pl-16 max-w-full right-0 flex"
                    >
                      <div class="w-screen max-w-md">
                        <Form
                          class="h-full"
                          @keypress.enter="submit"
                          @submit="submit"
                          v-slot="{ errors }"
                          v-click-outside="dismiss"
                        >
                          <div
                            class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                          >
                            <div class="flex-1 overflow-y-auto">
                              <div class="flex-1 flex flex-col justify-between">
                                <div
                                  class="px-4 divide-y divide-gray-200 sm:px-6"
                                >
                                  <div
                                    v-if="newUser"
                                    class="space-y-6 pt-6 pb-5"
                                  >
                                    <ProfileImage
                                      class="w-full justify-center items-center"
                                      :firstname="newUser.firstname"
                                      :lastname="newUser.lastname"
                                      :id="newUser.id"
                                      :image="
                                        base64Url ? base64Url : newUser.image
                                      "
                                      :allow-change="true"
                                      size="w-140 h-140"
                                      @showCropper="toggleCropper"
                                    />
                                    <div class="space-y-1">
                                      <InputField
                                        :title="$t('profile.firstname')"
                                        :placeholder="$t('game.name')"
                                        v-model="newUser.firstname"
                                        required-input
                                        rules="required|min:2|max:255"
                                      />

                                      <InputField
                                        :title="$t('profile.lastname')"
                                        :placeholder="$t('game.name')"
                                        v-model="newUser.lastname"
                                        required-input
                                        rules="required|min:2|max:255"
                                      />

                                      <InputField
                                        :title="$t('profile.email')"
                                        :placeholder="$t('game.name')"
                                        v-model="newUser.email"
                                        :disabled="true"
                                        rules="required|min:4|max:255"
                                      />
                                      <Button
                                        v-if="!showPasswordReset"
                                        button-type="primary"
                                        :title="$t('profile.change_password')"
                                        @clickAction="changePassword"
                                      />
                                      <div v-if="showPasswordReset">
                                        <div
                                          class="border border-red-600 shadow rounded-md px-4 py-5 sm:p-3"
                                        >
                                          <h3
                                            class="text-lg leading-6 font-medium text-gray-900"
                                          >
                                            {{
                                              $t("layout.reset_password_title")
                                            }}
                                          </h3>
                                          <div
                                            class="mt-2 max-w-xl text-sm leading-5 text-gray-500"
                                          >
                                            <p>
                                              {{
                                                error
                                                  ? error
                                                  : showPasswordSuccessMessage
                                                    ? $t(
                                                        "layout.reset_password_send",
                                                      )
                                                    : $t(
                                                        "layout.reset_password_text",
                                                      )
                                              }}
                                            </p>
                                          </div>
                                          <div
                                            v-if="!showPasswordSuccessMessage"
                                            class="mt-5"
                                          >
                                            <Button
                                              class="mr-3"
                                              button-type="secondary"
                                              :title="
                                                $t(
                                                  'profile.cancel_change_password',
                                                )
                                              "
                                              @clickAction="changePassword"
                                            />
                                            <Button
                                              button-type="primary"
                                              :loading="passwordResetLoading"
                                              :title="$t('button.send')"
                                              @clickAction="resetPassword"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end"
                            >
                              <Button
                                button-type="primary"
                                :title="$t('editor.save')"
                                :loading="loading"
                                :invalid="Object.keys(errors).length !== 0"
                                @clickAction="submit"
                              />
                              <Button
                                class="ml-3"
                                type="button"
                                button-type="secondary"
                                :title="$t('editor.cancel')"
                                @clickAction="cancel"
                              />
                            </div>
                          </div>
                        </Form>
                      </div>
                    </section>
                  </div>
                  <ImageCropper
                    v-if="showCropper"
                    :id="newUser.id"
                    :image="newUser.image"
                    @setImage="setImage"
                    @close="toggleCropper"
                  />
                </div>

                <!-- <div
                  v-if="cardShow"
                  v-click-outside="onClickOutside"
                  class="arrow_box w-260 rounded-15 bg-secondaryColor absolute bottom-0 left-0 z-25 ml-272 mb-15 flex flex-col "
                >
                  <div
                    class="flex items-center  border-transparentLine rounded-sm p-15 justify-between"
                  >
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from "./ProfileImage";
import { formatDistance } from "date-fns";
import Button from "../form/Button";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";
import InputField from "../form/InputField";
import ImageCropper from "@/components/profile/ImageCropper";
const authRepository = RepositoryFactory.get("auth");

const usersRepository = RepositoryFactory.get("user");

export default {
  name: "ProfileCard",
  components: {
    InputField,
    ProfileImage,
    Button,
    ImageCropper,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateUserData", "cancel"],
  data() {
    return {
      showResetModal: false,
      editing: false,
      firstname: "",
      lastname: "",
      loading: false,
      showCropper: false,
      base64Url: null,
      showPasswordSuccessMessage: false,
      error: "",
      showPasswordReset: false,
      passwordResetLoading: false,
      newUser: null,
    };
  },
  computed: {
    cardShow: {
      get: function () {
        return this.show;
      },
      set: function () {},
    },
  },
  mounted() {
    this.newUser = this.user;
    this.firstname = this.newUser?.firstname;
    this.lastname = this.newUser?.lastname;
  },
  methods: {
    onClickOutside() {
      if (!this.showCropper) {
        this.$emit("updateUserData");
      }
    },
    submit() {
      this.loading = true;
      usersRepository
        .updateUser({
          id: this.newUser.id,
          firstname: this.newUser.firstname,
          lastname: this.newUser.lastname,
          image: this.newUser.image,
        })
        .then((result) => {
          this.$store.dispatch("userStore/GET_CURRENT_USER");
          this.$emit("updateUserData");
          showNotification(
            this.$t("notifications.user_edited"),
            this.$t("notifications.the_user_is_successfully_edited"),
            "success",
          );
          this.loading = false;
        })
        .catch((err) => {
          showNotification(
            this.$t("notifications.editing_went_wrong"),
            this.$t("notifications.something_went_wrong_editing_your_data"),
            "error",
          );
          errorHandlingAndMessage(err);
          this.loading = false;
        });
    },
    changePassword() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    toggleCropper() {
      this.showCropper = !this.showCropper;
    },
    setImage(url, base64Url) {
      this.newUser.image = url;
      this.base64Url = base64Url;
    },
    resetPassword() {
      this.passwordResetLoading = true;
      authRepository
        .resetPassword(this.newUser.email)
        .then(() => {
          this.showPasswordSuccessMessage = true;
          showNotification(
            this.$t("notifications.password_reset"),
            this.$t("notifications.email_sent"),
            "success",
          );
          this.$emit("cancel");
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_resetting_the_password",
            ),
          );
          this.error = this.$t("layout.something_went_wrong");
        })
        .finally(() => (this.passwordResetLoading = false));
    },
    cancel() {
      this.showPasswordReset = false;
      this.$emit("updateUserData");
    },
    dismiss() {
      if (!this.showCropper) {
        this.cancel();
      }
    },
  },
};
</script>
