<template>
  <div class="flex justify-center items-center h-full bg-secondaryColor">
    <div class="flex justify-center flex-col items-center">
      <p
        class="text-140 font-black leading-none bg-secondaryColor text-primaryColor px-5 py-10 rounded-lg mb-30"
      >
        {{ error }}
      </p>
      <p class="text-40 mt-12 leading-none font-normal text-white">
        <span v-if="error === '403'">
          {{ $t("error.you_dont_access_to_this_page") }}
        </span>
        <span v-else-if="error === '404'">
          {{ $t("error.page_not_found") }}
        </span>
        <span v-else>
          {{ $t("error.oops_something_went_wrong") }}
        </span>
      </p>
      <p
        class="text-20 leading font-extralight none text-white cursor-pointer hover:bg-primaryOpacity mt-16 py-2 px-4"
        @click="toHome"
      >
        {{ $t("error.to_home_page") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  props: {
    error: {
      type: String,
      default: "500",
    },
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
