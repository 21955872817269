import Repository from "./Repository";

const resource = "/api/v3/translations";

export default {
  getAvailableTranslations() {
    return Repository.get(`${resource}/languages`);
  },
  exportTranslation(entityName, entityId) {
    return Repository.get(`${resource}/download/${entityName}/${entityId}`);
  },
  importTranslation(entityName, entityId, payload) {
    return Repository.post(
      `${resource}/upload/${entityName}/${entityId}`,
      payload,
    );
  },
  getAppTranslations(locale) {
    return Repository.get(`${resource}/app/${locale}`);
  },
  getRecentFiles() {
    return Repository.get(`${resource}/recent-files`);
  },
};
