<template>
  <div
    class="w-gameCardWidth h-gameCardHeight border-2 shadow-darkBg rounded-15 flex flex-col"
    :class="[activeColor]"
  >
    <div class="flex-1 flex flex-row">
      <div class="bg-grey">
        <p class="ml-5 mt-5 font-bold text-20 text-white">
          {{ $t("home.description") }}
        </p>
        <p class="ml-5 mt-1 text-14 text-white font-normal">
          {{ game.description ? game.description : "No description" }}
        </p>
      </div>
      <div class="flex-1 flex justify-end mr-5">
        <font-awesome-icon
          :icon="['fa', 'undo']"
          class="ml-5 mt-25 text-24 text-lightAlineaColor cursor-pointer hover:text-lightAlineaColor"
          @click="$emit('backFlip')"
        />
      </div>
    </div>
    <div class="flex-1 flex flex-col">
      <p class="ml-5 font-bold text-20 text-white">
        {{ $t("home.downloads") }}
      </p>
      <div class="flex flex-wrap">
        <a
          v-for="item in downloadableFiles"
          :key="item.id"
          :href="item.url"
          target="_blank"
          rel="noopener noreferrer"
          :title="item.platform_name"
        >
          <div
            class="ml-5 mt-1 p-2 rounded-md grid justify-items-center text-white cursor-pointer hover:bg-primaryColor transition: ease-in-out duration-150"
          >
            <font-awesome-icon
              :icon="['fab', `${item.icon}`]"
              class="mx-2 text-24"
            />
          </div>
        </a>
        <p
          v-if="
            !game.storeLinks.playstore &&
            !game.storeLinks.appstore &&
            !game.downloadableFile &&
            (!game.alternativeDownloadableFiles ||
              !game.alternativeDownloadableFiles.length)
          "
          class="ml-5 mt-1 text-14 text-white font-normal"
        >
          {{ $t("home.no_alternative_downloads") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBack",
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeColor: function () {
      if (this.game.active) return "bg-secondaryColor border-primaryColor";
      else return "bg-gray-400 border-gray-600 opacity-50";
    },
    downloadableFiles: function () {
      let downloadableFiles = [];
      if (this.game.downloadableFile) {
        downloadableFiles.push({
          icon: this.game.downloadableFile.platform.data.icon.substr(6),
          id: this.game.downloadableFile.platform.data.id,
          platform_name:
            this.game.downloadableFile.platform.data.name +
            " (" +
            this.$t("game.your_platform") +
            ")",
          url: this.game.downloadableFile.file,
        });
      }
      if (this.game.storeLinks.playstore) {
        downloadableFiles.push({
          icon: "google-play",
          id: null,
          platform_name: "play store",
          url: this.game.storeLinks.playstore,
        });
      }
      if (this.game.storeLinks.appstore) {
        downloadableFiles.push({
          icon: "app-store-ios",
          id: null,
          platform_name: "app store",
          url: this.game.storeLinks.appstore,
        });
      }
      if (this.game.alternativeDownloadableFiles) {
        this.game.alternativeDownloadableFiles.forEach((item) => {
          downloadableFiles.push({
            icon: item.platform.data.icon.substr(6),
            id: item.platform.data.id,
            platform_name: item.platform.data.name,
            url: item.file,
          });
        });
      }

      return downloadableFiles;
    },
  },
};
</script>
