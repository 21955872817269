<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-start w-2/3 h-8/12 bg-gray-50 p-8 rounded-md pb-5 md:w-1/3"
      v-click-outside="close"
    >
      <div class="flex justify-end">
        <button
          @click="close"
          class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{ $t("editor.sort_chapters").toUpperCase() }}
      </div>
      <div class="flex flex-col h-full pr-8 mt-2 overflow-y-hidden">
        <div class="flex flex-row border-b mb-4">
          <div
            class="w-5/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800"
          >
            {{ $t("editor.name") }}
          </div>
        </div>
        <div class="-my-2 sm:-mx-6 lg:-mx-8 overflow-y-auto">
          <div
            class="py-2 px-2 align-middle inline-block min-w-full sm:pl-6 lg:pl-8"
          >
            <div class="w-full mb-2">
              <draggable
                v-if="newChapters"
                @end="changed($event)"
                v-model="newChapters"
                group="chapters"
                item-key="id"
              >
                <template #item="{ element }">
                  <div
                    :key="element.id"
                    :id="element.id"
                    class="flex flex-row w-full mb-2 bg-primaryColor shadow rounded-md cursor-move"
                  >
                    <div
                      class="w-11/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-50 truncate"
                    >
                      {{ element.name }}
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import draggable from "vuedraggable";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";
import { showNotification } from "@/services/notificationService";
import { mapState } from "vuex";

export default {
  name: "Sort",
  components: { ModalContainer, draggable },
  data() {
    return {
      newChapters: [],
    };
  },
  computed: {
    ...mapState("trainingStore", ["chapters", "versionId", "trainingId"]),
  },
  mounted() {
    this.newChapters = this.chapters;
  },
  methods: {
    async changed(event) {
      const newIndex = event.newDraggableIndex;
      const oldIndex = event.oldDraggableIndex;
      const chapterId = event.item.id;
      if (newIndex !== oldIndex) {
        const chapter = {
          sort: newIndex,
        };
        trainingRepository
          .editChapter(this.trainingId, this.versionId, chapterId, chapter)
          .then((resp) => {
            this.$store.dispatch("trainingStore/GET_CHAPTERS");
            showNotification(
              this.$t("notifications.sort_chapter"),
              this.$t("notifications.chapter_is_successfully_sorted"),
              "success",
            );
          })
          .catch((err) => {
            showNotification(
              this.$t("notifications.sort_chapter"),
              this.$t(
                "notifications.something_went_wrong_while_sorting_this_chapter",
              ),
              "error",
            );
          });
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
