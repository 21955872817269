export default {
  "auth": {
    "account_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activatie"])},
    "activation_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!! Er ging iets mis, de activatie code is niet meer geldig."])},
    "activation_succes_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeah!! Je account is geactiveerd. Veel plezier met de games!"])},
    "check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check code"])},
    "eight_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal acht tekens"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mail adres is al in gebruik."])},
    "email_in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mail adres is al in gebruik, probeer de wachtwoord vergeten knop op de login pagina."])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "firstname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De voornaam voldoet niet aan de vereisten. Het mag tussen de 2 en 255 karakters bevatten."])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
    "hooray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEEJ!!"])},
    "invalid_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De combinatie van gebruikersnaam en wachtwoord komen niet overeen"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "lastname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De achternaam voldoet niet aan de vereisten. Het mag tussen de 2 en 255 karakters bevatten."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "one_lowercase_letter_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een kleine letter vereist"])},
    "one_number_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een nummer vereist"])},
    "one_or_more_fields_do_not_meet_the_constraints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een of meerdere velden voldoen niet aan de eisen"])},
    "one_uppercase_letter_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een hoofdletter vereist"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is niet geldig."])},
    "password_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord fouten"])},
    "password_is_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is niet toegestaan"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen met elkaar"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
    "register_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account registreren"])},
    "registration_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren gelukt"])},
    "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onthoud mij"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig wachtwoord"])},
    "some_required_fields_are_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige verplichte velden zijn leeg"])},
    "something_went_wrong_while_registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het registreren."])},
    "succeeded_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een email verzonden met de activatie link, activeer uw account binnen 24 uur. Veel plezier met spelen!"])},
    "the_reset_link_is_no_longer_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze link in niet langer geldig"])},
    "there_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn"])},
    "there_is_an_email_send_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een email verzonden naar"])},
    "to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie code"])},
    "virtualstudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VirtualStudio©"])},
    "welcome_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom bij"])},
    "where_you_can_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hierin kunt u uw wachtwoord wijzigen"])},
    "wrong_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout e-mailadres"])},
    "you_have_entered_an_invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft een ongeldige gebruikersnaam of wachtwoord ingevoerd"])}
  },
  "breadcrumbs": {
    "active-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actieve gebruikers"])},
    "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten"])},
    "customers/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klanten/"])},
    "customers/create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klanten/aanmaken"])},
    "customers/edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klanten/aanpassen"])},
    "customersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant aanmaken"])},
    "customersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant details"])},
    "customersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant aanpassen"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "dealersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Aanmaken"])},
    "dealersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer details"])},
    "dealersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer aanpassen"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanpassen"])},
    "gameCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakters"])},
    "gameCharactersCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter toevoegen"])},
    "gameCharactersEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter aanpassen"])},
    "highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscores"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen"])},
    "lesson-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level scores"])},
    "lesson-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level tijden"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn profiel"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
    "playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeltijd"])},
    "playtime-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeltijd scores"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportages"])},
    "reporting/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage"])},
    "reporting/active-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage/actieve-gebruikers"])},
    "reporting/download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage/download"])},
    "reporting/lesson-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage/level-score"])},
    "reporting/lesson-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage/level-times"])},
    "reporting/playtime-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportage/speeltijd-score"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "results-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten/details"])},
    "results/": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "settings/parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen/parameters"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
    "usersDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker details"])},
    "versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versies"])}
  },
  "button": {
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "delete_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder alles"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOAD"])},
    "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaande Gebruiker toevoegen"])},
    "new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Gebruiker toevoegen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan..."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updaten"])},
    "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updaten..."])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading..."])}
  },
  "customers": {
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activatie code"])},
    "add_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant toevoegen"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant ID"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant Overzicht"])},
    "customers_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant aanmaken"])},
    "customers_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant details"])},
    "customers_details_and_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant details en informatie"])},
    "customers_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant aanpassen"])},
    "customers_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers van klant"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingeschakeld"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "nr_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal klanten"])},
    "nr_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal gebruikers"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal gebruikers"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "stop_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopdatum"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
  },
  "dealers": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
    "add_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer toevoegen"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
    "add_user_to_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg gebruiker toe aan customer"])},
    "add_user_to_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg gebruiker toe aan dealer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant ID"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten van dealer"])},
    "dealer_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer aanmaken"])},
    "dealer_details_and_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer details en informatie"])},
    "dealer_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer aanpassen"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers Overzicht"])},
    "dealership_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers van dealer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingeschakeld"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer gebruikers activiteiten rapport"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "no_available_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beschikbare gebruikers"])},
    "nr_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal klanten"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal gebruikers"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer datums"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "stop_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopdatum"])}
  },
  "download": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_failed_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaden mislukt, probeer het opnieuw"])},
    "download_succeeded_check_your_download_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaden gelukt, check je download folder."])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer gebruikers activiteiten report"])},
    "here_is_your_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw rapportage"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestanden worden verwerkt, dit kan enkele minuten duren."])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een datumbereik"])},
    "this_form_will_close_in_5_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze dialoog sluit na 5 seconden..."])},
    "translation_export_creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestanden kan je terugvinden in 'Recent geëxporteerde vertalingen' zodra ze klaar zijn."])},
    "user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker activiteit rapport"])},
    "user_activity_report_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker activiteit rapport download"])},
    "you_did_not_download_your_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand is nog niet gedownload"])}
  },
  "editor": {
    "action_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie verwijderd"])},
    "action_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie is succesvol verwijderd"])},
    "action_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie eigenschappen"])},
    "action_property_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie eigenschap vertalingen"])},
    "action_selector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer actie"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "add_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie toevoegen"])},
    "add_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk Toevoegen"])},
    "add_first_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg eerste actie toe"])},
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter toevoegen"])},
    "add_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item toevoegen"])},
    "add_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level toevoegen"])},
    "add_new_linear_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe lineaire stap toevoegen"])},
    "add_new_setup_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe setup stap toevoegen"])},
    "add_non_linear_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Lineaire Stap Toevoegen"])},
    "add_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk Toevoegen"])},
    "add_to_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan checklist toevoegen"])},
    "all_steps_and_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle stappen en acties"])},
    "are_you_sure_you_want_to_delete_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze actie wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze chapter wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je dit level wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze stap wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze subchapter wilt verwijderen?"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedrag"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leegmaken"])},
    "constraint_error_the_value_of_this_action_property_does_not_meet_the_constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beperking fout: De waarde van deze actie eigenschap voldoet niet aan de vereisten"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd naar clipboard!"])},
    "correct_behavior_the_player_will_be_rewarded_with_score_when_finishing_this_step_otherwise_the_player_will_lose_the_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct gedrag, de speler word beloond met een score wanneer de speler deze stap voltooid, anders verliest de speler de score"])},
    "correct_behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct gedrag"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
    "create_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap creëren"])},
    "delete_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder actie"])},
    "delete_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter verwijderen"])},
    "delete_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level verwijderen"])},
    "delete_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap verwijderen"])},
    "delete_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter verwijderen"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschakelen"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgeschakeld"])},
    "edit_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk Bewerken"])},
    "edit_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter bewerken"])},
    "edit_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level bewerken"])},
    "edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk modus"])},
    "edit_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk Bewerken"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschakelen"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
    "enabled_or_disable_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel de stap aan of uit"])},
    "enter_a_name_for_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een naam in voor deze stap"])},
    "enter_edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start bewerk modus"])},
    "error_file_has_not_been_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwing: Er ging iets mis tijdens het uploaden van dit bestand, probeer het opnieuw"])},
    "exit_edit_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit bewerk modus"])},
    "export_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer les"])},
    "export_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer stap"])},
    "export_this_lesson_as_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer deze les als JSON file"])},
    "export_this_step_as_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer deze stap als JSON file"])},
    "export_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer vertalingen"])},
    "export_translations_as_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export vertalingen als CSV"])},
    "go_to_game_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar game karakters"])},
    "go_to_game_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar game vertalingen"])},
    "go_to_score_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar score overzicht"])},
    "go_to_trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar trofeeën"])},
    "import_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar met importeren"])},
    "import_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer les"])},
    "import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer stap"])},
    "import_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer vertalingen"])},
    "intended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedoeld"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductie"])},
    "is_on_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat op de checklist"])},
    "is_vr_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschikt voor Virtual Reality"])},
    "jump_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring naar"])},
    "level_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level vertalingen"])},
    "linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lineair"])},
    "linear_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lineaire eigenschappen"])},
    "linear_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lineaire stappen"])},
    "listen_to_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luister naar preview"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "main_steps_container_number_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Main stappen container (", _interpolate(_named("number")), " stappen)"])},
    "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max score"])},
    "maximum_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale score"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "new_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe audio"])},
    "new_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe video"])},
    "no_action_or_step_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actie of stap geselecteerd"])},
    "no_game_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je dient eerst een game aan te maken en een versie the synchroniseren om de editor te kunnen gebruiken.."])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten"])},
    "non_linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Lineair"])},
    "non_linear_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineaire eigenschappen"])},
    "non_linear_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineair random"])},
    "non_linear_random_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineair willekeurige nummer"])},
    "non_linear_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineaire stappen"])},
    "number_of_possible_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal mogelijke herhalingen"])},
    "opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kans"])},
    "opportunity_the_player_will_be_rewarded_with_points_when_finishing_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kans, the speler word beloond met een score wanneer de speler deze stap voltooid"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde"])},
    "passing_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slagingspercentage"])},
    "preparing_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...voorbereiden"])},
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenschappen"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepubliceerd"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie"])},
    "regenerate_tts_confirmation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opnieuw genereren van alle tekst naar spraak voor een level brengt kosten met zich mee. "])},
    "regenerate_tts_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op!"])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "repeatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaalbaar"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist"])},
    "required_this_is_a_required_step_to_execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist, dit is een vereiste step"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "results_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten voor"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "score_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score overzicht"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "select_a_valid_json_and_upload_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een JSON en upload"])},
    "select_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer game"])},
    "select_game_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer game versie"])},
    "selected_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde game"])},
    "selected_game_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde game versie"])},
    "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
    "setup_steps_container_number_steps": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Setup stappen container (", _interpolate(_named("number")), " stappen)"])},
    "something_went_wrong_deleting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens ver verwijderen van deze actie"])},
    "sort_chapters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteer chapters"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap"])},
    "step_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step naam"])},
    "synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesynced"])},
    "the_step_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De stap is succesvol gecreëerd"])},
    "there_is_an_problem_with_one_of_the_action_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem met een van de actie eigenschappen. Open deze actie om het probleem te inspecteren"])},
    "this_action_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie bevat een fout!"])},
    "this_action_property_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie eigenschap bevat een fout!"])},
    "this_game_action_does_not_exist_anymore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze game actie bestaat niet meer, verwijder deze actie om het probleem op te lossen"])},
    "this_lesson_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze les bevat een fout"])},
    "this_step_contains_an_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze stap bevat een fout!"])},
    "total_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal score"])},
    "total_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal stappen"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "type_error_the_value_of_this_action_property_does_not_have_the_correct_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld bevat een ongeldige waarde (type of opties zijn gewijzigd)"])},
    "upload_csv_to_import_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload een CSV bestand om vertalingen te importeren"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOADEN"])},
    "upload_json_to_import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload een correcte JSON file om een stap te importeren"])},
    "upload_json_to_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload een JSON en importeer een les"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...uploading"])},
    "value_must_be_either_empty_or_0_or_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze waarde moet of leeg zijn of 0 of meer zijn"])},
    "your_download_is_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je download is klaar! Je kan het bestand vinden in de downloads van je browser."])}
  },
  "error": {
    "oops_something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Er ging iets fout, neem contact met ons op."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan pagina niet vinden"])},
    "to_home_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar home pagina"])},
    "you_dont_access_to_this_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt geen toegang tot deze pagina."])}
  },
  "fields": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])}
  },
  "game": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "add_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Game"])},
    "app_store_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App store link"])},
    "are_you_sure_you_want_to_delete_this_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u dit spel wilt verwijderen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "create_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel aanmaken"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
    "edit_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel aanpassen"])},
    "editors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editors"])},
    "game_started_in_a_new_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit spel is geopend in een nieuw venster!"])},
    "google_play_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google play link"])},
    "if_nothing_happens_please_make_sure_the_game_has_been_downloaded_on_your_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als er niks gebeurd, controleer of de game is gedownload op je computer"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductie"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "online_gameplay_is_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelen in je browser is op dit moment niet beschikbaar"])},
    "online_gameplay_quota_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het tegoed is op"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "press_the_button_below_to_download_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op de knop hieronder om de game te downloaden"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplicht"])},
    "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheim"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik weet het zeker"])},
    "the_game_will_automatically_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit spel word automatisch geopend"])},
    "this_game_will_be_deleted_permanently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit spel word permanent verwijderd"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto uploaden"])},
    "web_gl_memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web GL memory"])},
    "your_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jouw platform"])}
  },
  "gamecharacters": {
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter toevoegen"])},
    "press_enter_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer deze stem"])},
    "remove_this_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder stem"])},
    "select_a_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer stem"])}
  },
  "general": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer"])},
    "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geleden"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veld leegmaken"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgeschakeld"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download dit spel"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "enter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam..."])},
    "exported_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geëxporteerd op"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrouwelijk"])},
    "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsnaam"])},
    "file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandstype"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mannelijk"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceer nu"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgeslagen"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootte"])},
    "uploaded_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geüpload op"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])}
  },
  "highscore": {
    "choose_different_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies aan ander spel"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscores"])},
    "no_highscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen highscores"])},
    "no_level_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen level geselecteerd"])},
    "no_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen levels"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speler"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Level"])},
    "something_went_wrong_while_getting_highscores_for_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het ophalen van de highscores voor dit level"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])}
  },
  "home": {
    "alternative_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatieve downloads"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uitgeschakeld"])},
    "download_and_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download & speel"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "no_alternative_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen alternatieve downloads"])},
    "not_available_on_this_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar op dit apparaat"])},
    "play_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu spelen"])}
  },
  "layout": {
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleer"])},
    "generate_user_activity_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer gebruikers activiteiten rapport"])},
    "please_fill_in_the_required_activation_code_you_received_from_your_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier de activatie code in die u heeft gekregen van uw bedrijf"])},
    "reset_password_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een email naar u verzonden, Check uw inbox!"])},
    "reset_password_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We gaan u een mail sturen om uw wachtwoord te resetten. Weet u zeker dat u uw huidige wachtwoord wilt resetten?"])},
    "reset_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
    "select_a_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer datums"])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan"])},
    "You_are_working_in_demo_mode_to_activate_your_account_press_the_button_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je werkt nu in demo modus, druk op de onderstaande knop om je account te activeren"])},
    "you_dont_have_a_student_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen speler rol"])},
    "you_have_been_redirected_to_our_new_application_please_use_this_link_in_the_future_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent doorgestuurd naar onze nieuwe applicatie, gebruik deze link om in de toekomst in te loggen"])},
    "you_have_entered_an_invalid_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft een ongeldige gebruikersnaam of wachtwoord ingevuld"])}
  },
  "media": {
    "finalizing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afronden"])},
    "no_media_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen media"])},
    "or_drag_and_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of sleep het bestand hierin"])},
    "recent_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente bestanden"])},
    "select_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer media"])},
    "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload een bestand"])},
    "upload_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Media"])},
    "upload_translation_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload vertalingen bestand (csv of json)"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaden"])}
  },
  "navigation": {
    "add_game_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakter toevoegen"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEHEER"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel"])},
    "game_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game karakters"])},
    "highscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highscore"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportage"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSTELLINGEN"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
  },
  "notifications": {
    "action_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie verwijderd"])},
    "action_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie is succesvol verwijderd"])},
    "actions_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie toegevoegd"])},
    "actions_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie bewerkt"])},
    "actions_was_successfully_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie succesvol toegevoegd"])},
    "actions_was_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie succesvol bewerkt"])},
    "add_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie toevoegen"])},
    "automatic_login_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch aanmelden is momenteel niet beschikbaar."])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
    "chapter_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk aangemaakt"])},
    "chapter_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter verwijderd"])},
    "chapter_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk aangepast"])},
    "chapter_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk is succesvol aangemaakt"])},
    "chapter_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is succesvol geïmporteerd"])},
    "chapter_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstuk is succesvol aangepast"])},
    "chapter_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter is succesvol gesorteerd"])},
    "character_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character opgeslagen"])},
    "character_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character verwijderd"])},
    "character_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character opgeslagen"])},
    "character_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character succesvol aangemaakt"])},
    "character_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character succesvol verwijderd"])},
    "character_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game character succesvol gewijzigd"])},
    "code_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code aangemaakt"])},
    "code_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code verwijderd"])},
    "code_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code aangepast"])},
    "code_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code is succesvol aangemaakt"])},
    "code_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code is succesvol aangepast"])},
    "copied_id_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID gekopieerd naar clipboard"])},
    "copy_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer ID"])},
    "copy_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap kopiëren"])},
    "create_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level aanmaken"])},
    "create_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap creëren"])},
    "customer_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant aangemaakt"])},
    "customer_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant verwijderd"])},
    "customer_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant bewerkt"])},
    "customer_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant is succesvol aangemaakt"])},
    "customer_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant is succesvol aangepast"])},
    "cut_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knip stap"])},
    "dealer_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer aangemaakt"])},
    "dealer_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer verwijderd"])},
    "dealer_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer bewerkt"])},
    "dealer_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer is succesvol aangemaakt"])},
    "dealer_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer is succesvol aangepast"])},
    "delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build verwijderen"])},
    "delete_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap verwijderen"])},
    "delete_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie verwijderen"])},
    "domain_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein aangemaakt "])},
    "domain_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein verwijderd"])},
    "domain_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein bewerkt"])},
    "duplicate_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceer stap"])},
    "edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie bewerken"])},
    "edit_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk level"])},
    "edit_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap bewerkt"])},
    "editing_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken ging fout"])},
    "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email verzonden"])},
    "filereader_api_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filereader API word niet ondersteund"])},
    "game_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel aangemaakt"])},
    "game_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel verwijderd"])},
    "game_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel aangepast"])},
    "game_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel is succesvol aangemaakt"])},
    "game_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel is succesvol verwijderd"])},
    "game_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel is succesvol aangepast"])},
    "import_lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer les"])},
    "import_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer stap"])},
    "invalid_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De combinatie van gebruikersnaam en wachtwoord komen niet overeen"])},
    "lesson_has_been_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les is succesvol geïmporteerd"])},
    "level_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level aangemaakt"])},
    "level_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level verwijderd"])},
    "level_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level aangepast"])},
    "level_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is succesvol aangemaakt"])},
    "level_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is succesvol verwijderd"])},
    "level_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level is succesvol aangepast"])},
    "level_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level succesvol gesorteerd"])},
    "login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen mislukt!"])},
    "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers"])},
    "no_user_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker geselecteerd"])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord resetten"])},
    "paste_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak actie"])},
    "paste_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak stap"])},
    "pasted_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak stap"])},
    "please_login_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld je handmatig aan in de game."])},
    "please_select_an_image_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een image bestand"])},
    "product_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product veld"])},
    "product_field_has_been_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit product veld is succesvol gewijzigd"])},
    "publish_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training gepubliceerd"])},
    "publish_training_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceren ging fout"])},
    "regenerate_tts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTS regenereren"])},
    "something_went_while_sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het sorteren"])},
    "something_went_wrong_activating_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het activeren van het account"])},
    "something_went_wrong_creating_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanmaken van een customer"])},
    "something_went_wrong_creating_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het spel aanmaken"])},
    "something_went_wrong_creating_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanmaken van een chapter"])},
    "something_went_wrong_creating_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het aanmaken van deze game character"])},
    "something_went_wrong_creating_this_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanmaken van een dealer"])},
    "something_went_wrong_creating_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanmaken van de game"])},
    "something_went_wrong_creating_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het aanmaken van deze gebruiker"])},
    "something_went_wrong_deleting_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verwijderen van de klant"])},
    "something_went_wrong_deleting_the_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verwijderen van deze build"])},
    "something_went_wrong_deleting_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verwijderen van de game"])},
    "something_went_wrong_deleting_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verwijderen van de gebruiker"])},
    "something_went_wrong_deleting_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verwijderen van deze versie"])},
    "something_went_wrong_deleting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verwijderen van deze actie"])},
    "something_went_wrong_deleting_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verwijderen van deze game karakter"])},
    "something_went_wrong_deleting_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verwijderen van de game"])},
    "something_went_wrong_downloading_the_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het downloaden van de csv"])},
    "something_went_wrong_editing_the_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het spel bewerken"])},
    "something_went_wrong_editing_the_TTS_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het bewerken van de TTS stem"])},
    "something_went_wrong_editing_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanpassen van een gebruiker"])},
    "something_went_wrong_editing_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het bewerken van een chapter"])},
    "something_went_wrong_editing_this_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanpassen van de game"])},
    "something_went_wrong_editing_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout met data aanpassen"])},
    "something_went_wrong_fetching_dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de dealers"])},
    "something_went_wrong_fetching_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het ophalen van de media"])},
    "something_went_wrong_getting_the_activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de activatie code"])},
    "something_went_wrong_getting_the_current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de gebruiker"])},
    "something_went_wrong_publishing_the_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het publiceren van deze training"])},
    "something_went_wrong_receiving_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de klanten"])},
    "something_went_wrong_receiving_dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de dealers"])},
    "something_went_wrong_receiving_game_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de game details"])},
    "something_went_wrong_receiving_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de gebruiker"])},
    "something_went_wrong_receiving_user_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de gebruiker details"])},
    "something_went_wrong_receiving_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het ophalen van de gebruikers"])},
    "something_went_wrong_refreshing_the_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het verversen van de auth token"])},
    "something_went_wrong_resetting_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het resetten van het wachtwoord"])},
    "something_went_wrong_saving_the_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het opslaan van de gebruiker"])},
    "something_went_wrong_updating_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het bewerken van een klant"])},
    "something_went_wrong_updating_this_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het wijzigen van deze game karakter"])},
    "something_went_wrong_updating_this_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het bewerken van een dealer"])},
    "something_went_wrong_uploading_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het uploaden van deze versie"])},
    "something_went_wrong_uploading_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het uploaden van dit bestand"])},
    "something_went_wrong_uploading_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets fout tijdens het aanpassen van de vertalingen "])},
    "something_went_wrong_while_adding_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het aanmaken van deze actie"])},
    "something_went_wrong_while_attempting_to_start_this_game_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het opstarten van de game, probeer het nogmaals"])},
    "something_went_wrong_while_converting_text_to_speech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het omzetten van tekst naar spraak"])},
    "something_went_wrong_while_creating_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het aanmaken van dit level"])},
    "something_went_wrong_while_deleting_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verwijderen van deze chapter"])},
    "something_went_wrong_while_deleting_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verwijderen van deze stap"])},
    "something_went_wrong_while_duplicating_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het kopiëren van deze actie"])},
    "something_went_wrong_while_duplicating_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het kopiëren van deze stap"])},
    "something_went_wrong_while_editing_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het bewerken van deze actie"])},
    "something_went_wrong_while_editing_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het bewerken van dit level"])},
    "something_went_wrong_while_editing_this_product_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het wijzigen van dit product veld"])},
    "something_went_wrong_while_editing_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het bewerken van deze stap"])},
    "something_went_wrong_while_exporting_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het exporteren van vertalingen"])},
    "something_went_wrong_while_fetching_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het ophalen van de categorieën"])},
    "something_went_wrong_while_fetching_product_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het ophalen van de product velden"])},
    "something_went_wrong_while_fetching_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het ophalen van dit level"])},
    "something_went_wrong_while_getting_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens get ophalen van deze actie"])},
    "something_went_wrong_while_importing_this_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het importeren van deze JSON"])},
    "something_went_wrong_while_importing_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het importeren van vertalingen"])},
    "something_went_wrong_while_moving_this_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verplaatsen van deze actie"])},
    "something_went_wrong_while_moving_this_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het verplaatsen van deze stap"])},
    "something_went_wrong_while_regenerating_tts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het regenereren van de TTS"])},
    "something_went_wrong_while_selecting_this_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het selecteren van deze versie"])},
    "something_went_wrong_while_sorting_this_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het sorteren van deze chapter"])},
    "something_went_wrong_while_sorting_this_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het sorteren van dit level"])},
    "something_went_wrong_while_sorting_this_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het sorteren van deze subchapter"])},
    "something_went_wrong_while_uploading_this_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis tijdens het uploaden van dit bestand"])},
    "sort_chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter sortering"])},
    "sort_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level sortering"])},
    "sort_subchapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter sortering"])},
    "step_copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap gekopiëerd naar klembord"])},
    "step_cut_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap geknipt naar klembord"])},
    "step_has_been_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap is succesvol geïmporteerd"])},
    "step_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap geplakt"])},
    "step_property_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step eigenschap geüpdatet"])},
    "step_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step geüpdatet"])},
    "sub_chapter_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk aangemaakt"])},
    "sub_chapter_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk verwijderd"])},
    "sub_chapter_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk aangepast"])},
    "sub_chapter_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk is succesvol aangemaakt"])},
    "sub_chapter_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk is succesvol verwijderd"])},
    "sub_chapter_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub hoofdstuk is succesvol aangepast"])},
    "subchapter_is_successfully_sorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter succesvol gesorteerd"])},
    "successfully_activated_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je account is nu geactiveerd. Alle levels zijn ontgrendeld"])},
    "successfully_activated_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesvol geactiveerd"])},
    "successfully_deleted_the_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build succesvol verwijderd"])},
    "successfully_deleted_the_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie succesvol verwijderd"])},
    "successfully_published_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training succesvol gepubliceerd"])},
    "successfully_uploaded_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie succesvol geüpload"])},
    "text_to_speech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst naar spraak"])},
    "the_step_is_succesfully_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step is succesvol verplaatst"])},
    "the_step_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De stap is succesvol aangemaakt"])},
    "the_step_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De stap is succesvol verwijderd"])},
    "the_step_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De stap is succesvol bewerkt"])},
    "the_user_is_successfully_added_to_the_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is toegevoegd aan de dealer"])},
    "the_user_is_successfully_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw data is succesvol aangepast"])},
    "there_are_no_users_to_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen gebruikers om toe te voegen"])},
    "there_is_no_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen gebruiker geselecteerd"])},
    "there_is_no_user_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen gebruiker geselecteerd"])},
    "this_action_has_been_successfully_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie is succesvol geplakt"])},
    "this_step_has_been_successfully_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze stap is succesvol gedupliceerd"])},
    "this_step_has_been_successfully_pasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze stap is succesvol geplakt"])},
    "translation_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaling import"])},
    "translation_successfully_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaling succesvol geïmporteerd"])},
    "trophy_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee aangemaakt"])},
    "trophy_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee verwijderd"])},
    "trophy_editted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee aangepast"])},
    "trophy_is_successfully_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee is succesvol aangemaakt"])},
    "trophy_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee is succesvol verwijderd"])},
    "trophy_is_successfully_editted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee is succesvol aangepast"])},
    "tts_regeneration_has_been_initialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTS wordt voor dit level opnieuw gegenereerd"])},
    "unable_to_start_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game kon niet gestart worden"])},
    "upload_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie uploaden"])},
    "user_added_to_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toegevoegd aan dealer"])},
    "user_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker verwijderd"])},
    "user_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker aangepast"])},
    "user_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker uitgenodigd"])},
    "user_is_successfully_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker succesvol verwijderd"])},
    "user_is_successfully_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker is succesvol uitgenodigd"])},
    "user_is_successfully_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker is succesvol geüpdatet"])},
    "user_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker geüpdate"])}
  },
  "optionsmenu": {
    "addAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie toevoegen"])},
    "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level toevoegen"])},
    "addLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lineaire stap toevoegen"])},
    "addNonLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineaire stap toevoegen"])},
    "addSetupStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup stap toevoegen"])},
    "addSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter toevoegen"])},
    "codeBranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game versies"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game verwijderen"])},
    "deleteChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter verwijderen"])},
    "deleteLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level verwijderen"])},
    "deleteSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter verwijderen"])},
    "downloadExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download export"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game bewerken"])},
    "editChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter bewerken"])},
    "editLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level bewerken"])},
    "editSubChapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subchapter bewerken"])},
    "exportChapterTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer chapter vertalingen"])},
    "exportLevelTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer vertalingen"])},
    "exportTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteer vertalingen"])},
    "importChapterTranslations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer chapter vertalingen"])},
    "importLesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer les"])},
    "importLevelTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer vertalingen"])},
    "importLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lineaire stap importeren"])},
    "importNonLinearStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lineaire stap importeren"])},
    "importSetupStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup stap importeren"])},
    "importTranslation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer vertalingen"])},
    "keyboardShortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toetsenbord sneltoetsen"])},
    "levelTranslations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media vertalingen"])},
    "pasteAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak actie"])},
    "pasteStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak stap"])},
    "recentTranslationFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent geëxporteerde vertalingen"])},
    "regenerateTts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regenerate TTS"])}
  },
  "pagination": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste"])},
    "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst"])},
    "total_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal items"])}
  },
  "profile": {
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "cancel_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer wachtwoord veranderen"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord aanpassen"])},
    "change_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto kiezen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijsnijden"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "member_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid voor"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "rotate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draaien"])},
    "set_profile_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies profiel foto"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur"])}
  },
  "reporting": {
    "active_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actieve gebruikers"])},
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slechtste"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereren"])},
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uur"])},
    "hours_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uren:Minuten"])},
    "level_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level scores"])},
    "level_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level tijden"])},
    "loading_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content aan het laden"])},
    "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
    "my_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn speeltijd"])},
    "name_a_z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam (A-Z)"])},
    "name_z_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam (Z-A)"])},
    "number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal gebruikers"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "playtime_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeltijd scores"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteer:"])},
    "the_end_date_is_before_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig bereik: De startdatum is voor de einddatum"])},
    "the_start_date_is_before_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig bereik: De einddatum is voor de startdatum"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beste"])},
    "total_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale speeltijd"])},
    "user_playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeltijd gebruiker"])},
    "value_high_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde (High-Low)"])},
    "value_low_high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde (Low-High)"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie:"])}
  },
  "results": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
    "attemps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogingen"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogingen"])},
    "avg_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde tijd"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "days_since_last_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer gespeeld"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mislukt"])},
    "filter_failed_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen gefaald"])},
    "filter_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg"])},
    "filter_passed_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen behaald"])},
    "filter_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])},
    "filter_unfinished_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen onafgewerkt"])},
    "game_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel resultaten"])},
    "games_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen voltooid"])},
    "games_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen mislukt"])},
    "games_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spellen gespeeld"])},
    "highscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["highscore"])},
    "hours_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uren gespeeld"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "level_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level naam"])},
    "level_passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level behaald"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["levels"])},
    "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale score"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resultaten"])},
    "no_time_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tijd"])},
    "no_trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen trofeeën"])},
    "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaald"])},
    "passing_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale score"])},
    "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strafpunten"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
    "personal_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke resultaten"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punten"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaat"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_of_the_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van de verplichte"])},
    "score_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score percentage"])},
    "scored_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gescoorde punten"])},
    "search_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek level"])},
    "the_average_points_earned_in_this_level_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het gemiddeld aantal punten behaald tijdens dit level is"])},
    "the_average_score_of_this_level_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gemiddelde score van dit level is"])},
    "the_average_time_spent_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gemiddelde tijd die mensen besteden aan deze les is"])},
    "the_original_lesson_where_this_result_was_scored_has_been_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking: De orginele les waar deze score is behaald is verwijderd."])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "total_penalties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale strafpunten"])},
    "total_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale score"])},
    "trophies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofeeën"])},
    "well_done_youve_completed_the_test_within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed gedaan! Je hebt deze les behaald binnen"])},
    "where_the_average_score_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar men een gemiddelde score behaalde van"])},
    "you_scored_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft een score behaald van"])}
  },
  "roles": {
    "ROLE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "ROLE_CUSTOMER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant Admin"])},
    "ROLE_DEALER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Admin"])},
    "ROLE_EDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
    "ROLE_STUDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speler"])},
    "ROLE_SUPER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
    "ROLE_TEACHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leraar"])},
    "ROLE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
    "ROLE_USER_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers Admin"])}
  },
  "settings": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept"])},
    "add_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stem Toevoegen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "default_text_to_speech_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard Tekst naar spraak stemmen"])},
    "default_text_to_speech_voices_descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel een standaard stem in per taal"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])},
    "header_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder stemmen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "subheader_voices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je alle stemmen codes wilt verwijderen?"])}
  },
  "shortcuts": {
    "collapse_all_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zichtbare stappen sluiten"])},
    "copy_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer ID"])},
    "copy_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer stap"])},
    "copy_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer stap/actie"])},
    "cut_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knip stap/actie"])},
    "duplicate_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceer step"])},
    "editor_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor sneltoetsen"])},
    "expand_all_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zichtbare stappen openen"])},
    "keyboard_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toetsenbord sneltoetsen"])},
    "paste_step_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak stap/actie"])},
    "quick_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle acties"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "show_full_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat hele titels zien"])},
    "timeline_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline sneltoetsen"])},
    "toggle_shortcut_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel sneltoetsen menu"])}
  },
  "streaming": {
    "navigate_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je het zeker?"])},
    "navigate_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeren naar een andere pagina zal je speelsessie stoppen."])},
    "navigate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je naar een andere pagina navigeert, zal je speelsessie worden gestopt. Het is mogelijk dat je hierdoor gegevens verliest."])},
    "session_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden van spelsessie..."])},
    "session_loading_extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het laden van de spelsessie duurt langer dan verwacht. Een moment geduld alsjeblieft."])},
    "session_loading_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het spel kon niet worden geladen. Probeer het later opnieuw."])}
  },
  "sync": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compleet"])},
    "copy_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie kopiëren"])},
    "copy_lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessen kopiëren"])},
    "copy_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps kopiëren"])},
    "diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diff"])},
    "diff_against_unity_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijken met Unity package"])},
    "diff_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diff status"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar!"])},
    "finish_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish sync"])},
    "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incompleet"])},
    "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
    "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor"])},
    "start_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start sync"])},
    "starting_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync starten"])},
    "sync_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync level"])},
    "sync_phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync fases"])},
    "sync_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisatie afgerond"])},
    "sync_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync status"])}
  },
  "translation": {
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
    "are_you_sure_you_want_to_delete_this_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je dit bestand wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze media wilt verwijderen?"])},
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand verwijderen"])},
    "delete_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media verwijderen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand"])},
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestands-naam"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])},
    "JsonAudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "JsonImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding"])},
    "JsonVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
    "last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst gewijzigd"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten..."])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen"])},
    "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
    "select_locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer taal"])},
    "showing_start_to_end_of_total_results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " tot ", _interpolate(_named("end")), " van ", _interpolate(_named("total")), " resultaten"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap"])},
    "this_media_translation_belongs_to_the_default_language_deleting_this_will_delete_all_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze media vertaling behoort tot de standaard taal. Als je deze verwijderd, verwijder je ook alle media van de andere talen "])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])}
  },
  "trophies": {
    "add_new_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe als nieuwe trofee"])},
    "add_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee toevoegen"])},
    "edit_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofee bewerken"])},
    "remove_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op enter om te verwijderen"])},
    "search_for_trophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een trofee"])},
    "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op enter om te selecteren"])},
    "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerd"])},
    "trophies_earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trofeeën na afronding"])},
    "trophies_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereiste trofeeën"])}
  },
  "tts": {
    "aws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS"])},
    "gamecharacter_change_regenerate_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien je een stem wijzigt voor een game karakter, wordt deze stem pas gebruikt zodra de tekst naar spraak opnieuw is gegenereerd."])},
    "google_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Cloud"])},
    "neural_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer een stem een 'neural' optie heeft, wordt deze gebruikt."])}
  },
  "users": {
    "a_user_has_to_be_assigned_to_either_a_customer_or_a_dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gebruiker moet zijn toegewezen een aan klant of aan een dealer"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker Toevoegen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "create_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker maken"])},
    "created_at_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruiker aangemaakt op ", _interpolate(_named("date"))])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
    "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
    "dealers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealers"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder account"])},
    "delete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u dit account wilt verwijderen? Alle gegevens worden permanent verwijderd. Deze actie kan niet ongedaan worden gemaakt"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschakelen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk"])},
    "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker bewerken"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adres"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "game_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel resultaten"])},
    "hold_shift_for_multiple_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdt shift in voor meerdere selectie"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief"])},
    "last_played_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer gespeeld"])},
    "last_time_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer gespeeld"])},
    "last_version_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste versie gespeeld"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid sinds"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
    "users_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers overzicht"])}
  },
  "validation": {
    "messages": {
      "min_one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 1 cijfer vereist"])},
      "min_one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 1 hoofdletter vereist"])}
    }
  },
  "versions": {
    "are_you_sure_you_want_to_delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze build wilt verwijderen?"])},
    "are_you_sure_you_want_to_delete_this_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze versie wilt verwijderen?"])},
    "delete_build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build verwijderen"])},
    "delete_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie verwijderen"])},
    "deleting_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van deze versie kan even duren, wacht na het bevestigen even aub"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie"])}
  },
  "warning": {
    "are_you_sure_you_want_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je wilt annuleren"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik weet het zeker"])},
    "there_is_data_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is data veranderd"])}
  }
}