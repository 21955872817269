<template>
  <div class="bg-gray-900 h-100vh flex flex-col">
    <Topbar />
    <div id="editor-container" class="flex flex-auto h-100 w-screen flex-row">
      <div
        v-if="visible"
        class="h-full bg-gray-800"
        :class="{ 'w-350': showNav, 'w-8': !showNav }"
      >
        <div
          v-if="!showNav"
          class="flex justify-center items-center h-100vh p-2 cursor-pointer"
          :class="{ 'bg-sidebar': !sidebarVisible }"
          @click="showNav = true"
        >
          <font-awesome-icon icon="chevron-right" class="text-white text-16" />
        </div>
        <div
          v-else
          class="flex flex-row h-100vh"
          :class="{ 'bg-sidebar': !sidebarVisible }"
        >
          <div
            v-if="sidebarVisible"
            id="side-bar"
            class="bg-gray-800 w-350"
            :class="propTitle ? 'border-l-4 border-activeAction' : 'm-l-px-4'"
          >
            <div class="h-30 flex justify-between items-center">
              <div
                class="flex w-30 justify-center items-center cursor-pointer bg-gray-800 hover:bg-editortabshover"
                @click="showNav = false"
              >
                <font-awesome-icon
                  icon="chevron-left"
                  class="text-white text-16"
                />
              </div>
              <font-awesome-icon
                icon="sliders-h"
                class="text-white text-16 mr-3"
              />
            </div>
            <Tab
              :title="propTitle"
              :show-translation-icon="activeType === 'action'"
              @toggleActionPropertiesTranslationModal="
                toggleActionPropertiesTranslationModal
              "
            >
              <StepProperties v-if="activeType === 'step'" :data="activeStep" />
              <ActionProperties
                v-if="activeType === 'action'"
                :data="activeAction"
              />
            </Tab>
          </div>
          <div
            v-else
            class="bg-sidebar h-30 w-30 flex justify-center items-center"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="text-white text-16"
              @click="sidebarVisible = !sidebarVisible"
            />
          </div>
        </div>
      </div>
      <div
        id="content"
        class="flex flex-auto overflow-y-auto overflow-x-hidden"
      >
        <router-view />
      </div>
      <QuickActionMenu v-if="$route.name === 'timeline'" />
      <ActionPropertiesTranslationModal
        v-if="showActionPropertiesTranslationModal"
        :action="activeAction.action"
        @toggleActionPropertiesTranslationModal="
          toggleActionPropertiesTranslationModal
        "
      />
    </div>
    <Notification />
  </div>
</template>

<script>
import StepProperties from "./components/tabs/StepProperties";
import ActionProperties from "./components/tabs/ActionProperties";
import Topbar from "./components/Topbar";
import Tab from "./components/Tab";
import Notification from "../../components/helpers/Notification";
import { mapState } from "vuex";
import QuickActionMenu from "@/views/editor/components/QuickActionMenu";
import ActionPropertiesTranslationModal from "@/views/editor/components/modals/ActionPropertiesTranslationModal";

export default {
  name: "EditorLayout",
  components: {
    Topbar,
    Tab,
    StepProperties,
    ActionProperties,
    Notification,
    ActionPropertiesTranslationModal,
    QuickActionMenu,
  },
  onBeforeRouteLeave(to, from, next) {
    this.showNav = false;
    next();
  },
  data() {
    return {
      sidebarVisible: true,
      showNav: false,
      showActionPropertiesTranslationModal: false,
    };
  },
  computed: {
    ...mapState("editorStore", [
      "activeStep",
      "activeType",
      "activeAction",
      "activeLesson",
    ]),
    propTitle: function () {
      if (this.activeType === "action") {
        return this.$t("editor.action_properties");
      } else if (this.activeType === "step") {
        if (this.activeStep) {
          return this.activeStep.kind === "linear"
            ? this.$t("editor.linear_properties")
            : this.$t("editor.non_linear_properties");
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    visible: function () {
      const routeArray = [
        "levels",
        "versions",
        "trophies",
        "gameTranslations",
        "lessonTranslations",
        "gameCharacters",
      ];
      return !routeArray.includes(this.$route.name);
    },
  },
  watch: {
    activeType: {
      handler(val) {
        if (val) {
          this.showNav = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.showNav = false;
  },
  created() {
    this.$store.dispatch("trainingStore/GET_TRAININGS");
    this.$store.dispatch("translationStore/GET_LOCALES");
  },
  methods: {
    toggleActionPropertiesTranslationModal() {
      this.showActionPropertiesTranslationModal =
        !this.showActionPropertiesTranslationModal;
    },
  },
};
</script>

<style></style>
