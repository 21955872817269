<template>
  <PageContentContainer>
    <div id="report-card" class="backgroundCard flex flex-col w-full p-1">
      <div class="flex flex-1 items-center flex-row">
        <ProfileImage
          :image="currentUser.image"
          :firstname="currentUser.firstname"
          :lastname="currentUser.lastname"
          :email="currentUser.email"
          size="w-70 h-70"
        />
        <div class="w-userImageWidth w-60 ml-1">
          <p class="font-bold text-24 text-lightAlineaColor leading-none">
            {{ currentUser.firstname + " " + currentUser.lastname }}
          </p>
          <p class="font-normal text-20 text-lightAlineaColor leading-none">
            {{
              currentUser.roles.includes("ROLE_SUPER_ADMIN")
                ? "Admin"
                : currentUser.roles.includes("ROLE_TEACHER")
                  ? "Teacher"
                  : "Player"
            }}
          </p>
        </div>
      </div>
      <div class="flex flex-4 flex-col">
        <div class="flex flex-1 flex-col mt-1">
          <OutputField
            :edit="editing"
            :title="$t('profile.firstname')"
            :error-message="errorMessages.firstname"
            v-model="currentUser.firstname"
            class="mb-1"
          />

          <OutputField
            :edit="editing"
            :title="$t('profile.lastname')"
            v-model="currentUser.lastname"
            :error-message="errorMessages.lastname"
            class="mb-1"
          />

          <OutputField
            :title="$t('profile.email')"
            v-model="currentUser.email"
            class="mb-1"
          />

          <OutputField :title="$t('profile.member_for')" :value="getDays" />
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-10">
      <Button
        :title="editing ? $t('profile.submit') : $t('profile.edit')"
        type="primary"
        :loading="loading"
        @clickAction="editing ? submit() : (editing = true)"
      />
      <Button
        v-if="editing"
        class="ml-10"
        :title="$t('profile.cancel')"
        type="primary"
        @clickAction="editing = false"
      />
    </div>
    <div class="flex flex-row mt-10">
      <Button
        :title="$t('profile.change_password')"
        button-type="secondary"
        @clickAction="changePassword"
      />
    </div>
    <ResetPasswordModal
      v-if="showResetModal"
      @cancel="showResetModal = false"
    />
  </PageContentContainer>
</template>

<script>
import { mapState } from "vuex";
import ResetPasswordModal from "../../components/helpers/modals/ResetPasswordModal";
import OutputField from "../../components/helpers/OutputField";
import Button from "../../components/form/Button";
import PageContentContainer from "../../components/helpers/PageContentContainer";
import ProfileImage from "../../components/profile/ProfileImage";
import { formatDistance } from "date-fns";
import { nl, enGB } from "date-fns/locale";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const usersRepository = RepositoryFactory.get("user");
import { errorHandlingAndMessage } from "@/services/ErrorService";

export default {
  name: "MyProfile",
  components: {
    OutputField,
    Button,
    PageContentContainer,
    ProfileImage,
    ResetPasswordModal,
  },
  data() {
    return {
      editing: false,
      changed: false,
      showResetModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    getDays: function () {
      return formatDistance(new Date(this.currentUser.created_at), new Date(), {
        locale: this.getLanguage,
      });
    },
    getLanguage: function () {
      switch (this.$i18n.locale) {
        case "nl":
          return nl;
        case "en":
          return enGB;
        default:
          return enGB;
      }
    },
  },
  created: function () {
    this.$store.dispatch("userStore/GET_CURRENT_USER");
  },
  methods: {
    submit() {
      this.loading = true;
      if (this.editing) {
        usersRepository
          .updateUser({
            id: this.currentUser.id,
            firstname: this.currentUser.firstname,
            lastname: this.currentUser.lastname,
          })
          .then((result) => {
            this.loading = false;
            this.$store.dispatch(
              "userStore/SET_CURRENT_USER",
              result.data.data,
            );
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t(
                "notifications.something_went_wrong_editing_this_chapter",
              ),
            );
            this.loading = false;
            this.editing = true;
          });
      }
      this.editing = !this.editing;
    },
    cancel() {
      this.editing = false;
    },
    changePassword() {
      this.showResetModal = true;
    },
  },
};
</script>

<style scoped></style>
