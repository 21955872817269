import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  faAppStoreIos,
  faGooglePlay,
  faWindows,
  faAndroid,
  faHtml5,
  faApple,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  fas,
  faAppStoreIos,
  faGooglePlay,
  faWindows,
  faAndroid,
  faHtml5,
  faApple,
);

export default library;
