import authRepository from "./AuthRepository.js";
import userRepository from "./UserRepository.js";
import trainingRepository from "./TrainingRepository.js";
import LessonRepository from "./LessonRepository.js";
import GameSessionRepository from "./GameSessionRepository.js";
import ReportingRepository from "./ReportingRepository.js";
import CustomerRepository from "./CustomerRepository";
import DealerRepository from "./DealerRepository";
import SettingsRepository from "./SettingsRepository.js";
import TtsRepository from "./TtsRepository";
import StepRepository from "./StepRepository";
import ActionRepository from "./ActionRepository";
import GameCharactersRepository from "@/repository/GameCharactersRepository";
import TranslationRepository from "@/repository/TranslationRepository";
import UploadRepository from "@/repository/UploadRepository";
import ProductFieldRepository from "@/repository/ProductFieldRepository";
import LessonMediaRepository from "@/repository/LessonMediaRepository";
import MediaRepository from "@/repository/MediaRepository";
import PlatformRepository from "@/repository/PlatformRepository";
import LocaleRepository from "@/repository/LocaleRepository";

const repositories = {
  auth: authRepository,
  training: trainingRepository,
  user: userRepository,
  lesson: LessonRepository,
  gameSession: GameSessionRepository,
  reporting: ReportingRepository,
  customer: CustomerRepository,
  dealer: DealerRepository,
  settings: SettingsRepository,
  tts: TtsRepository,
  step: StepRepository,
  action: ActionRepository,
  gameCharacters: GameCharactersRepository,
  translation: TranslationRepository,
  upload: UploadRepository,
  productField: ProductFieldRepository,
  lessonMedia: LessonMediaRepository,
  media: MediaRepository,
  platform: PlatformRepository,
  locale: LocaleRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
