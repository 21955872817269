import { h, resolveComponent } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Layout from "@/Layout";
import store from "@/store/store.js";
import Changelog from "@/views/Changelog.vue";
import Home from "@/views/Home.vue";
import Profile from "@/views/profile/Profile.vue";
import Highscores from "@/views/Highscores.vue";
import Results from "@/views/Results.vue";
import GameDetails from "@/views/GameDetails.vue";
import Login from "@/views/authentication/Login.vue";
import Register from "@/views/authentication/Register.vue";
import ActivationCode from "@/views/authentication/ActivationCode.vue";
import ReportingOverview from "@/views/reporting/Overview.vue";
import ForgotPassword from "@/views/authentication/ForgotPassword.vue";
import ResetPassword from "@/views/authentication/ResetPassword.vue";
import Playtime from "@/views/reporting/Playtime.vue";
import ActiveUsers from "@/views/reporting/ActiveUsers.vue";
import LessonScore from "@/views/reporting/LessonScore.vue";
import PlaytimeScore from "@/views/reporting/PlaytimeScore.vue";
import LessonTimes from "@/views/reporting/LessonTimes.vue";
import Download from "@/views/reporting/Download.vue";
import Users from "@/views/users/Users.vue";
import Timeline from "@/views/editor/Timeline.vue";
import Levels from "@/views/editor/Levels.vue";
import EditorLayout from "@/views/editor/EditorLayout.vue";
import GameCharacters from "@/views/editor/GameCharacters";
import DealersOverview from "./views/dealers/Overview.vue";
import DealerEdit from "@/views/dealers/Edit.vue";
import DealerCreate from "@/views/dealers/Create.vue";
import DealerDetail from "@/views/dealers/Details";
import CustomersOverview from "@/views/customers/Overview.vue";
import CustomerEdit from "@/views/customers/Edit.vue";
import CustomerCreate from "@/views/customers/Create.vue";
import CustomerDetail from "@/views/customers/Details.vue";
import Versions from "@/views/Versions.vue";
import Trophies from "@/views/editor/Trophies.vue";
import MediaTranslations from "@/views/editor/MediaTranslations";
import ErrorPage from "@/views/errors/ErrorPage.vue";

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active-link",
  linkExactActiveClass: "exact-active-link",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/forgotpassword",
      name: "forgot-password",
      component: ForgotPassword,
    },
    {
      path: "/reset_password",
      name: "reset-password",
      component: ResetPassword,
      props: (route) => ({
        token: route.query.token,
        email: route.query.email,
      }),
    },
    {
      path: "/api/email/verify/:id/:token",
      redirect: (to) => {
        return {
          path: "/email/verify/" + to.params.id + "/" + to.params.token,
        };
      },
    },
    {
      path: "/email/verify/:id/:token",
      name: "activation-code",
      component: ActivationCode,
      props: true,
    },
    {
      path: "/error/:error",
      name: "error-page",
      component: ErrorPage,
      props: true,
    },
    {
      path: "/:pathMatch(.*)*",
      component: ErrorPage,
      props: { error: 404 },
    },
    {
      path: "/",
      name: "",
      component: Layout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/",
          name: "home",
          component: Home,
        },
        {
          path: "results",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "results",
              component: Results,
            },
            {
              path: "details/:id",
              name: "results-details",
              component: GameDetails,
              props: true,
            },
          ],
        },
        {
          path: "highscores",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "highscores",
              component: Highscores,
              props: true,
            },
          ],
        },
        {
          path: "reporting",
          component: ReportingOverview,
          children: [
            {
              path: "",
              name: "playtime",
              component: Playtime,
            },
            {
              path: "active-user",
              name: "active-user",
              component: ActiveUsers,
            },
            {
              path: "lesson-score",
              name: "lesson-score",
              component: LessonScore,
            },
            {
              path: "playtime-score",
              name: "playtime-score",
              component: PlaytimeScore,
            },
            {
              path: "lesson-times",
              name: "lesson-times",
              component: LessonTimes,
            },
            {
              path: "download",
              name: "download",
              component: Download,
            },
          ],
        },
        {
          path: "users",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "users",
              component: Users,
              props: true,
            },
            {
              path: "details/:id",
              name: "usersDetail",
              component: Results,
              props: true,
            },
          ],
        },
        {
          path: "dealers",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "dealers",
              component: DealersOverview,
              props: true,
            },
            {
              path: "create",
              name: "dealersCreate",
              component: DealerCreate,
              props: true,
            },
            {
              path: "edit/:id",
              name: "dealersEdit",
              component: DealerEdit,
              props: true,
            },
            {
              path: "details/:id",
              name: "dealersDetail",
              component: DealerDetail,
              props: true,
            },
          ],
        },
        {
          path: "customers",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "customers",
              component: CustomersOverview,
            },
            {
              path: "create",
              name: "customersCreate",
              component: CustomerCreate,
              props: true,
            },
            {
              path: "edit/:id",
              name: "customersEdit",
              component: CustomerEdit,
              props: true,
            },
            {
              path: "details/:id",
              name: "customersDetail",
              component: CustomerDetail,
              props: true,
            },
          ],
        },
        {
          path: "my-profile",
          name: "my-profile",
          component: Profile,
        },
        {
          path: "changelog",
          name: "changelog",
          component: Changelog,
        },
        {
          path: "/games/:id/versions",
          name: "versions",
          component: Versions,
          props: true,
        },
      ],
    },
    {
      path: "/editor",
      component: EditorLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "levels",
          component: Levels,
        },
        {
          path: "/timeline/:id",
          name: "timeline",
          component: Timeline,
          props: true,
        },
        {
          path: "/editor/:id/media-translations",
          name: "gameTranslations",
          component: MediaTranslations,
          props: true,
        },
        {
          path: "/timeline/:id/media-translations",
          name: "lessonTranslations",
          component: MediaTranslations,
          props: true,
        },
        {
          path: "/trophies",
          name: "trophies",
          component: Trophies,
        },
        {
          path: "/game-characters",
          name: "gameCharacters",
          component: GameCharacters,
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const urlToken = new URLSearchParams(window.location.search).get("token");
  const path = to.path;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["authStore/isAuthenticated"]) {
      next();
      return;
    } else if (urlToken) {
      await localStorage.setItem("user-token", urlToken);
      store.dispatch("authStore/SET_TOKEN", urlToken);
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
