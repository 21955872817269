<template>
  <div
    v-if="trainings"
    class="flex flex-row justify-center items-center cursor-pointer"
  >
    <v-select
      class="select-training w-auto min-w-56 cursor-pointer"
      v-model="selectedTraining"
      :options="trainings"
      :selectable="(training) => training.versions.length"
      :disabled="isDisabled"
      label="title"
      :clearable="false"
      @update:modelValue="selectTraining"
    >
      <template #option="option">
        <div class="font-bold cursor-pointer">
          <span>
            {{ option.title }}
          </span>
        </div>
      </template>
      <template #selected-option="option">
        <div class="font-bold cursor-pointer">
          <span>
            {{ option.title }}
          </span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
export default {
  name: "TopBarGameSelector",
  props: {
    modelValue: {
      type: String,
      default: "Value",
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      open: false,
      trainingsList: [],
      allTrainings: [],
      selectedTraining: null,
      showTooltip: false,
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "trainings",
      "trainingId",
      "activeTraining",
      "versionId",
    ]),
  },
  watch: {
    trainings: {
      handler(val) {
        this.setSelectedTraining();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.trainings.length) {
      this.selectedTraining = this.trainings.filter((value) => {
        return value.id === this.trainingId;
      });
      this.setSelectedTraining();
    }
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    setSelectedTraining() {
      if (this.trainingId) {
        const selectedTraining = this.trainings.filter((value) => {
          return value.id === this.trainingId;
        });
        this.setActiveTraining(selectedTraining[0]);
      } else {
        const training = this.trainings.filter((training) => {
          return training.version;
        });
        this.setActiveTraining(training[0]);
      }
    },
    selectTraining(training) {
      const selectedTraining = this.trainings.filter((value) => {
        return value.id === training.id;
      });
      this.setActiveTraining(selectedTraining[0]);
    },
    setActiveTraining(game) {
      if (game) {
        this.selectedTraining = game;
        this.$store.dispatch("trainingStore/SET_TRAINING_ID", {
          trainingId: game.id,
        });
        const storedVersionId = localStorage.getItem("versionId");

        const gameHasVersionId = game.versions.filter((value) => {
          return value.id === storedVersionId;
        });
        this.$store.dispatch(
          "trainingStore/SET_CURRENT_VERSION",
          gameHasVersionId[0]?.id ?? game.version ?? game.versions[0].id,
        );

        this.$store
          .dispatch("trainingStore/SET_ACTIVE_EDITOR_TRAINING", game)
          .then(() => {
            this.$emit("forceComponentRefresh");
          });
      }
    },
  },
};
</script>

<style>
.select-training {
  color: #233876;
  background: #f9fafb;
  border-radius: 15rem;
  width: 17rem;
  height: 2.5rem;
  box-shadow: 1px 2px;
}
.select-training .vs__dropdown-toggle,
.select-training .style-chooser__title,
.select-training .vs-state-active-color {
  background: transparent;
  border-color: transparent;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 5rem;
  padding-left: 0.5rem;
  margin-top: 0.3rem;
}

.select-training .vs-dropdown-bg,
.select-training .vs__dropdown-menu {
  margin-top: 0.5rem;
  background: #f9fafb;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
}

.select-training .vs__dropdown-option {
  padding: 0.5rem;
  background: #f9fafb;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.select-training .vs__dropdown-option:hover {
  background: #3b82f6;
  color: #f9fafb;
}

.select-training .vs--disabled .vs__clear,
.vs--disabled .vs__selected {
  cursor: default;
  background-color: transparent;
}

.vs__search {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.vs--disabled .vs__open-indicator {
  width: 0px;
}

.select-training .vs__selected {
  color: #233876;
}

.select-training .vs__open-indicator {
  fill: #233876;
}

.select-training .vs__dropdown-menu::-webkit-scrollbar {
  width: 10px;
  border-radius: 15rem;
}

.select-training .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15rem;
}

.select-training .vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 15rem;
}
</style>
