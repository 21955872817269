<template>
  <!-- left side of card -->
  <div
    id="left-container"
    class="left-container flex flex-1 flex-col background mobile:hidden bg-secondaryColor"
  >
    <div id="title-container" class="flex flex-2 items-center justify-center">
      <p class="text-title text-lightAlineaColor">
        {{ $t("auth.welcome_to").toUpperCase() }}
      </p>
    </div>
    <div
      id="logo-container"
      class="flex flex-9 justify-center items-center px-inputPaddingLeftRight"
    >
      <img
        id="company-logo"
        class="w- object-contain"
        :src="require(`../../assets/theme/logo.png`)"
        :alt="$t('auth.virtualstudio')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftContainer",
  props: {
    button: {
      type: String,
      default: "button",
    },
  },
};
</script>

<style scoped>
.background {
  background-position: right center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
