<template>
  <div
    id="top-bar"
    class="h-60 bg-primaryColor flex justify-between items-center px-5"
  >
    <div class="flex flex-1 flex-row">
      <div class="flex justify-start">
        <div
          class="flex items-center justify-center cursor-pointer hover:bg-secondaryColor rounded-full p-3"
          :class="{
            'bg-secondaryColor': this.$route.name === 'home',
          }"
          @click="$router.push({ name: 'home' })"
        >
          <font-awesome-icon icon="home" class="text-white text-20 w-6 h-6" />
        </div>
        <div class="flex items-center justify-center mx-3">
          <font-awesome-icon
            icon="long-arrow-alt-right"
            class="text-white text-20"
          />
        </div>
        <div
          class="flex items-center justify-center rounded-full p-3"
          :class="{
            'bg-secondaryColor': this.$route.name === 'levels',
            'cursor-pointer hover:bg-secondaryColor':
              this.$route.name !== 'levels',
          }"
          @click="
            $route.name === 'levels' ? '' : $router.push({ name: 'levels' })
          "
        >
          <font-awesome-icon
            :icon="this.$route.name === 'versions' ? 'tags' : 'layer-group'"
            class="text-white text-20 w-6 h-6"
          />
        </div>
        <div
          v-if="
            this.$route.name === 'timeline' ||
            this.$route.name === 'translations'
          "
          class="flex items-center justify-center mx-3"
        >
          <font-awesome-icon
            icon="long-arrow-alt-right"
            class="text-white text-20"
          />
        </div>
        <div
          v-if="
            this.$route.name === 'timeline' ||
            this.$route.name === 'translations'
          "
          class="flex items-center justify-center rounded-full p-3"
          :class="{
            'bg-secondaryColor': this.$route.name === 'timeline',
            'cursor-pointer hover:bg-secondaryColor':
              this.$route.name !== 'timeline',
          }"
          @click="
            $route.name === 'timeline' ? '' : $router.push({ name: 'timeline' })
          "
        >
          <font-awesome-icon
            icon="sliders-h"
            class="text-white text-20 w-6 h-6"
          />
        </div>
      </div>

      <TopbarGameSelector
        :is-disabled="this.$route.name !== 'levels'"
        class="ml-3"
      />

      <div
        v-if="versions && currentVersion"
        class="flex flex-row justify-center items-center ml-3"
      >
        <v-select
          v-model="currentVersion"
          class="select-version"
          label="version_number"
          :clearable="false"
          :searchable="false"
          :disabled="this.$route.name !== 'levels'"
          :selectable="(option) => option.synced"
          :options="versions"
          :reduce="(version) => version.id"
          @update:modelValue="setCurrentVersion"
        >
          <!--          v-tooltip="{-->
          <!--          content: $t(-->
          <!--          this.$route.name === 'levels'-->
          <!--          ? 'editor.select_game_version'-->
          <!--          : 'editor.selected_game_version'-->
          <!--          ),-->
          <!--          delay: 300,-->
          <!--          boundary: 'window',-->
          <!--          show: showTooltip,-->
          <!--          triggers: []-->
          <!--          }"-->
          <template #option="option">
            <div
              class="flex flex-row justify-between items-center font-bold"
              :class="option.synced ? '' : 'opacity-50'"
            >
              <div class="flex flex-row items-center">
                <span>
                  {{ option.version_number }}
                </span>
              </div>
              <div class="flex flex-row justify-end items-center text-gray-50">
                <span
                  class="text-xs rounded-full py-1 px-2 ml-3 shadow"
                  :class="option.published ? 'bg-green-500' : 'bg-orange-400'"
                >
                  {{ $t("editor.published") }}
                </span>
                <span
                  class="text-xs rounded-full py-1 px-2 ml-2 shadow"
                  :class="option.synced ? 'bg-green-500' : 'bg-orange-400'"
                  >{{ $t("editor.synced") }}
                </span>
              </div>
            </div>
          </template>
          <template #selected-option="option">
            <div
              class="flex flex-row justify-between items-center font-bold"
              @mouseenter="showTooltip = true"
              @mouseleave="showTooltip = false"
            >
              <div class="flex flex-row items-center">
                <span>
                  {{ option.version_number }}
                </span>
              </div>
              <div class="flex flex-row justify-end items-center">
                <span
                  class="text-xs text-gray-50 rounded-full py-1 px-2 ml-3 shadow"
                  :class="option.published ? 'bg-green-500' : 'bg-orange-400'"
                >
                  {{ $t("editor.published") }}
                </span>
                <span
                  class="text-xs text-gray-50 rounded-full py-1 px-2 ml-2 shadow"
                  :class="option.synced ? 'bg-green-500' : 'bg-orange-400'"
                  >{{ $t("editor.synced") }}
                </span>
              </div>
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div
      v-if="this.$route.name === 'timeline' && this.activeLesson"
      class="flex items-center justify-center font-medium text-md rounded-full text-white p-3"
    >
      <span>
        {{ this.activeLesson.name }}
      </span>
    </div>

    <div class="flex flex-1 justify-end h-full items-center">
      <div
        v-if="this.$route.name === 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full text-white hover:bg-secondaryColor p-3"
        v-tooltip="{
          content: $t('editor.go_to_score_overview'),
          delay: 300,
          boundary: 'window',
        }"
        @click="toggleModal"
      >
        <span>
          {{ $t("editor.score_overview") }}
        </span>
      </div>
      <div
        v-if="this.$route.name === 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full hover:bg-secondaryColor p-3"
        v-tooltip="{
          content: $t('editor.search'),
          delay: 300,
          boundary: 'window',
        }"
        @click="$store.dispatch('searchStore/SHOW_SEARCH_MODAL')"
      >
        <font-awesome-icon icon="search" class="text-white text-20 w-6 h-6" />
      </div>

      <div
        v-if="this.$route.name !== 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full hover:bg-secondaryColor p-3 mr-2"
        :class="{
          'bg-secondaryColor': this.$route.name === 'gameTranslations',
        }"
        v-tooltip="{
          content: $t('editor.go_to_game_translations'),
          delay: 300,
          boundary: 'window',
        }"
        @click="
          $route.name === 'gameTranslations'
            ? ''
            : $router.push({
                name: 'gameTranslations',
                params: { id: trainingId },
              })
        "
      >
        <font-awesome-icon
          icon="photo-video"
          class="text-white text-20 w-6 h-6 outline-none"
        />
      </div>
      <div
        v-if="this.$route.name === 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full hover:bg-secondaryColor p-3 mr-2"
        v-tooltip="{
          content: $t('editor.level_translations'),
          delay: 300,
          boundary: 'window',
        }"
      >
        <div class="relative inline-block text-left mt-1">
          <div>
            <OptionMenu
              class="text-white text-20 w-6 h-6"
              icon="cog"
              color="text-gray-50"
              :hover="false"
              :options="settingOptions"
              @importLevelTranslation="
                showTranslationModal('import', 'lesson', $route.params.id)
              "
              @exportLevelTranslation="
                showTranslationModal('export', 'lesson', $route.params.id)
              "
              @regenerateTts="showTtsConfirmationModal = true"
              @keyboardShortcuts="toggleShortcutModal"
              @recentTranslationFiles="
                showTranslationModal('files', 'lesson', $route.params.id)
              "
            />
          </div>
        </div>
      </div>
      <div
        v-if="this.$route.name !== 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full hover:bg-secondaryColor p-3 mr-2"
        :class="{ 'bg-secondaryColor': this.$route.name === 'trophies' }"
        v-tooltip="{
          content: $t('editor.go_to_trophies'),
          delay: 300,
          boundary: 'window',
        }"
        @click="
          $route.name === 'trophies' ? '' : $router.push({ name: 'trophies' })
        "
      >
        <font-awesome-icon
          icon="trophy"
          class="text-white text-20 w-6 h-6 outline-none"
        />
      </div>

      <div
        v-if="this.$route.name !== 'timeline'"
        class="flex items-center justify-center cursor-pointer rounded-full hover:bg-secondaryColor p-3"
        :class="{ 'bg-secondaryColor': this.$route.name === 'gameCharacters' }"
        v-tooltip="{
          content: $t('editor.go_to_game_characters'),
          delay: 300,
          boundary: 'window',
        }"
        @click="
          $route.name === 'gameCharacters'
            ? ''
            : $router.push({ name: 'gameCharacters' })
        "
      >
        <font-awesome-icon
          icon="user-plus"
          class="text-white text-20 w-6 h-6 outline-none"
        />
      </div>
    </div>
    <ScoreOverviewModal v-if="showModal" @close="toggleModal" />
    <div v-if="showImportModal && $route.name === 'timeline'">
      <MediaModal
        media-modal-type="translation"
        :title="$t('media.upload_translation_file')"
        :required="true"
        @toggleMediaModal="closeTranslationModal('import')"
        @finishUpload="finishUpload"
      />
    </div>
    <div v-if="showTranslationFilesModal && $route.name === 'timeline'">
      <FilesModal
        media-modal-type="recent-translation-files"
        :title="$t('media.upload_translation_file')"
        :required="true"
        @toggleMediaModal="closeTranslationModal('files')"
      />
    </div>
    <div v-if="showSelectLocaleModal && $route.name === 'timeline'">
      <SelectLocaleModal
        :file-name="fileName"
        @cancel="showSelectLocaleModal = false"
        @confirm="finishTranslationImport"
      />
    </div>
    <div v-if="showExportModal && $route.name === 'timeline'">
      <ExportModal
        :header-text="$t('editor.export_translations')"
        target="translation"
        :sub-header-text="$t('editor.export_translations_as_csv')"
        @close="closeTranslationModal('export')"
        :async="true"
      />
    </div>
    <ShortcutModal
      v-if="showShortcutModal"
      :operating-system="operatingSystem"
      @close="toggleShortcutModal"
    />
    <GeneralModal
      @keydown.esc="showTtsConfirmationModal = false"
      v-if="showTtsConfirmationModal"
      @ok="
        showTtsConfirmationModal = false;
        regenerateTts();
      "
      @cancel="showTtsConfirmationModal = false"
      :title="$t('editor.regenerate_tts_confirmation_title')"
      :message="$t('editor.regenerate_tts_confirmation_message')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopbarGameSelector from "@/views/editor/components/inputs/TopbarGameSelector";
import ScoreOverviewModal from "@/views/editor/components/modals/ScoreOverviewModal";
import OptionMenu from "@/components/helpers/OptionMenu";
import uploadTranslationHelper from "@/mixins/uploadTranslationHelper";
import ShortcutModal from "@/views/editor/components/modals/ShortcutModal";
import FilesModal from "@/views/editor/components/modals/filesModal/FilesModal";
import { getOS } from "@/helpers/osCheck";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import GeneralModal from "@/components/helpers/modals/GeneralModal.vue";
const ttsRepository = RepositoryFactory.get("tts");

export default {
  name: "Topbar",

  components: {
    FilesModal,
    GeneralModal,
    TopbarGameSelector,
    ScoreOverviewModal,
    OptionMenu,
    ShortcutModal,
  },

  mixins: [uploadTranslationHelper],

  data() {
    return {
      currentVersion: "",
      showModal: false,
      showShortcutModal: false,
      showTooltip: false,
      operatingSystem: "windows",
      showTtsConfirmationModal: false,
    };
  },

  computed: {
    ...mapState("editorStore", ["activeLesson"]),
    ...mapState("trainingStore", [
      "activeEditorTraining",
      "versions",
      "trainingId",
      "versionId",
    ]),
    ...mapState("searchStore", ["showSearchModal"]),
    ...mapState("userStore", ["currentUser"]),
    settingOptions: function () {
      let options = [
        { option: "importLevelTranslation", name: "Import CSV Translations" },
        { option: "exportLevelTranslation", name: "Export CSV Translations" },
        { option: "recentTranslationFiles", name: "Recent exported files" },
        { option: "keyboardShortcuts", name: "Keyboard Shortcuts" },
      ];

      if (
        this.currentUser &&
        this.currentUser?.roles?.includes("ROLE_SUPER_ADMIN")
      ) {
        options.push({
          option: "regenerateTts",
          name: "Regenerate TTS",
        });
      }

      return options;
    },
  },

  watch: {
    versions: {
      handler() {
        this.currentVersion = this.versions.find(
          (version) => version.id === this.versionId,
        );
      },
      deep: true,
    },
  },

  created() {
    this.$store.dispatch("userStore/GET_CURRENT_USER");

    window.addEventListener("keydown", (e) => {
      if (
        e.keyCode === 70 &&
        ((this.operatingSystem === "macos" && e.metaKey) ||
          (this.operatingSystem === "windows" && e.ctrlKey))
      ) {
        e.preventDefault();
        this.$store.dispatch("searchStore/SHOW_SEARCH_MODAL");
      }
      if (this.showSearchModal && e.keyCode === 27) {
        this.$store.dispatch("searchStore/HIDE_SEARCH_MODAL");
      }
      if (e.keyCode === 191 && e.shiftKey && e.ctrlKey) {
        e.preventDefault();
        this.toggleShortcutModal();
      }
    });
  },

  mounted() {
    this.currentVersion = localStorage.getItem("versionId");
    this.operatingSystem = getOS();
  },

  methods: {
    setCurrentVersion() {
      this.$store
        .dispatch("trainingStore/SET_CURRENT_VERSION", this.currentVersion)
        .then(() => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
        });
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },

    toggleShortcutModal() {
      this.showShortcutModal = !this.showShortcutModal;
    },

    regenerateTts() {
      ttsRepository
        .regenerateTts(this.activeLesson.id)
        .then(() => {
          showNotification(
            this.$t("notifications.regenerate_tts"),
            this.$t("notifications.tts_regeneration_has_been_initialized"),
            "success",
          );
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.regenerate_tts"),
            this.$t(
              "notifications.something_went_wrong_while_regenerating_tts",
            ),
            "error",
          );
        });
    },
  },
};
</script>

<style>
.select-version {
  color: #233876;
  background: #f9fafb;
  border-radius: 15rem;
  width: 18rem;
  height: 2.5rem;
  box-shadow: 1px 2px;
}
.select-version .vs__dropdown-toggle,
.select-version .style-chooser__title,
.select-version .vs-state-active-color {
  background: transparent;
  border-color: transparent;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 5rem;
  padding-left: 0.5rem;
  margin-top: 0.2rem;
}
.select-version .vs-dropdown-bg,
.select-version .vs__dropdown-menu {
  margin-top: 0.5rem;
  background: #f9fafb;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
}

.select-version .vs__dropdown-option {
  padding: 0.5rem;
  background: #f9fafb;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.select-version .vs__dropdown-option:hover {
  background: #3b82f6;
  color: #f9fafb;
}

.select-version .vs--disabled .vs__clear,
.vs--disabled .vs__selected,
.vs--disabled .vs__selected-options,
.vs--disabled .vs__actions,
.vs--disabled .vs2__combobox {
  cursor: default;
  background-color: transparent;
}

.vs--disabled .vs__open-indicator {
  width: 0px;
}

.select-version .vs__selected {
  color: #233876;
}
.select-version .vs__open-indicator {
  fill: #233876;
}
/* width */
.select-version .vs__dropdown-menu::-webkit-scrollbar {
  width: 10px;
  border-radius: 15rem;
}

/* Handle */
.select-version .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15rem;
}

/* Handle on hover */
.select-version .vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 15rem;
}
</style>
