import ApiV4Repository from "@/repository/ApiV4Repository";

const resource = "/api/v4/game-characters";

export default {
  getGameCharacterSettings() {
    return ApiV4Repository.get(`${resource}`);
  },

  getGameCharacter(id) {
    return ApiV4Repository.get(`${resource}/${id}`);
  },

  updateGameCharacter(payload) {
    return ApiV4Repository.patch(`${resource}/${payload.id}`, {
      name: payload.name,
      voices: payload.voices,
    });
  },

  createGameCharacter(payload) {
    return ApiV4Repository.post(`${resource}`, {
      name: payload.name,
      voices: payload.voices,
    });
  },

  deleteGameCharacter(payload) {
    return ApiV4Repository.delete(`${resource}/${payload}`);
  },
};
