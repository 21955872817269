<template>
  <!--  <ValidationProvider-->
  <!--    :name="title"-->
  <!--    :rules="`${requiredInput ? 'required' : ''}||min:1`"-->
  <!--    v-slot="{ errors }"-->
  <!--  >-->
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <textarea
        v-on="validationListeners"
        :style="{
          borderColor: activeColor,
        }"
        type="text"
        :placeholder="defaultValue"
        v-model="newValue"
        rows="9"
        @focusin="toggleShortcuts(true)"
        @focusout="toggleShortcuts(false)"
        class="form-input block w-full text-gray-200 placeholder-gray-600 bg-sidebar pr-10 sm:text-sm sm:leading-5"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errors,
        }"
      />
      <div>
        <label
          class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-auto flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          @click="synthesizeToUrl"
          >{{ $t("editor.listen_to_preview") }}
        </label>
      </div>
      <div v-if="audioSrc">
        <vue-plyr>
          <audio autoplay>
            <source :src="audioSrc" type="audio/mp3" />
            <source :src="audioSrc" type="audio/ogg" />
            <source :src="audioSrc" type="audio/wav" />
          </audio>
        </vue-plyr>
      </div>
      <div
        v-if="errors"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errors" class="text-sm text-red-600">
        {{ errors }}
      </p>
    </div>
  </div>
  <!--  </ValidationProvider>-->
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
const ttsRepository = RepositoryFactory.get("tts");
import { disableShortcuts } from "@/services/disableShortcutsService";
import useValidation from "@/helpers/useValidation";
import { mapState } from "vuex";

export default {
  components: { ErrorPopup },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inputType: {
      type: String,
      default: "text",
    },
    siblingData: {
      type: Object,
      default: () => {},
    },
    voiceRelation: {
      type: String,
      default: "",
      required: false,
    },
    defaultTtsVoice: {
      type: String,
      default: "",
      required: false,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const {
      validationListeners,
      errorMessage: errors,
      inputValue,
      meta,
      updateField,
    } = useValidation(props, emit);

    return {
      validationListeners,
      errors,
      inputValue,
      updateField,
      meta,
    };
  },
  data() {
    return {
      newValue: this.modelValue ?? this.defaultValue,
      audioSrc: null,
      loading: false,
      selectedGameCharacterName: this.defaultTtsVoice,
    };
  },
  computed: {
    ...mapState("translationStore", ["defaultLocale"]),
  },
  watch: {
    siblingData: {
      handler() {
        if (this.siblingData.propertyName === this.voiceRelation) {
          this.audioSrc = null;
          this.selectedGameCharacterName = this.siblingData.value;
        }
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.audioSrc = null;
      this.$emit("update:modelValue", this.newValue);
    },
    synthesizeToUrl() {
      this.loading = true;
      this.audioSrc = null;
      const payload = {
        ssml: this.newValue,
        locale: this.defaultLocale,
        game_character_name: this.selectedGameCharacterName,
      };
      ttsRepository
        .synthesizeToUrl(payload)
        .then((result) => {
          this.audioSrc = result.data.data.audio_url;
          this.loading = false;

          if (this.audioSrc === null) {
            showNotification(
              this.$t("notifications.text_to_speech"),
              this.$t(
                "notifications.something_went_wrong_while_converting_text_to_speech",
              ),
              "error",
            );
          }
        })
        .catch((error) => {
          let extraErrorText = "";
          if (
            error.response.status === 422 &&
            error.response.data.errors.ssml
          ) {
            extraErrorText = error.response.data.errors.ssml[0];
          }
          showNotification(
            this.$t("notifications.text_to_speech"),
            this.$t(
              "notifications.something_went_wrong_while_converting_text_to_speech",
            ) +
              ": " +
              extraErrorText,
            "error",
          );
        });
    },
    toggleShortcuts(boolean) {
      disableShortcuts(boolean);
    },
  },
};
</script>

<style></style>
