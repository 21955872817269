<template>
  <ModalContainer @cancel="close">
    <div class="flex flex-col justify-between w-1/2 bg-gray-50 p-4 rounded-md">
      <p
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{ $t("editor.add_subchapter").toUpperCase() }}
      </p>
      <Form
        class="flex flex-col h-full justify-between"
        @keypress.enter.stop="preventSubmitOnEnter ? null : addSubChapter"
        @submit="addSubChapter"
        v-slot="{ errors }"
      >
        <div class="flex flex-col">
          <InputField
            :title="$t('editor.name')"
            placeholder="Name"
            v-model="subChapter.name"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            :title="$t('editor.introduction')"
            placeholder="Intro"
            v-model="subChapter.introduction"
            required-input
            rules="required|min:4|max:255"
          />
          <TextArea
            :title="$t('editor.description')"
            placeholder="Description"
            v-model="subChapter.description"
            @preventSubmit="preventSubmit"
            required-input
            rules="required|min:4|max:255"
          />
          <CheckBox
            :title="$t('editor.active')"
            v-model="subChapter.active"
          ></CheckBox>
          <photo
            class="flex-1 w-1/2 mr-6"
            :title="$t('translation.image')"
            align="column"
            :url="imageUrl"
            :loading="!uploadReady"
            :show-origional="!uploadSuccessful"
            @newImage="newImage"
          ></photo>
        </div>
        <!-- <ImageField class="mt-3" @click.native="showMediaModal = true" /> -->
        <div class="flex flex-row justify-end">
          <Button
            class="mr-3"
            type="submit"
            :loading="loading"
            button-type="primary"
            :title="$t('editor.save')"
            :invalid="Object.keys(errors).length !== 0"
          />
          <Button
            type="button"
            button-type="tertiary"
            :title="$t('editor.cancel')"
            @clickAction="close"
          />
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import InputField from "@/components/form/InputField";
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import CheckBox from "@/components/form/Checkbox";
import { showNotification } from "@/services/notificationService";
import Button from "@/components/form/Button";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";
import Photo from "@/components/form/Photo.vue";
import uploadRepository from "@/repository/UploadRepository";
import axios from "axios";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import TextArea from "@/components/form/TextArea.vue";

export default {
  name: "AddSubChapter",
  components: { TextArea, Photo, InputField, Button, ModalContainer, CheckBox },
  data() {
    return {
      showMediaModal: false,
      subChapter: {
        name: "",
        introduction: "",
        description: "",
        active: true,
        sort: 0,
      },
      loading: false,
      dataChanged: false,

      imageUrl: "",
      uploadReady: true,
      uploadSuccessful: false,
      preventSubmitOnEnter: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versionId", "chapter"]),
  },
  methods: {
    addSubChapter() {
      this.subChapter.parent = this.chapter.id;
      this.loading = true;
      trainingRepository
        .newChapter(this.trainingId, this.versionId, this.subChapter)
        .then(() => {
          this.loading = false;
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.sub_chapter_created"),
            this.$t("notifications.sub_chapter_is_successfully_created"),
            "success",
          );
          this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
        });
    },
    newImage(newImage) {
      this.uploadReady = false;
      uploadRepository
        .createSignedUrl(newImage["name"])
        .then(async (result) => {
          await axios
            .put(result.data.data.url, newImage)
            .then(() => {
              this.subChapter.image = result.data.data.url.split("?")[0];
              this.uploadSuccessful = true;
            })
            .catch((err) => {
              errorHandlingAndMessage(
                err,
                this.$t(
                  "notifications.something_went_wrong_uploading_this_file",
                ),
              );
              this.uploadSuccessful = false;
            })
            .finally(() => {
              this.uploadReady = true;
            });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_uploading_this_file"),
          );
          this.uploadSuccessful = false;
          this.uploadReady = true;
        });
    },
    close() {
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
    },
    preventSubmit(boolean) {
      this.preventSubmitOnEnter = boolean;
    },
  },
};
</script>

<style></style>
