import { RepositoryFactory } from "@/repository/RepositoryFactory";
const stepRepository = RepositoryFactory.get("step");
const actionRepository = RepositoryFactory.get("action");
import { showNotification } from "@/services/notificationService";
import i18n from "@/i18n";
import { errorHandlingAndMessage } from "@/services/ErrorService";

const state = {
  chapters: [],
  activeStep: "",
  activeAction: "",
  activeType: "", // step / action / lesson
  activeLevel: "test 2",
  activeTrack: [],
  activeLesson: null,
  setupSteps: [],
  mainSteps: [],
  totalSteps: 0,
  totalStepsLoaded: [],
};

const getters = {
  activeLevel(state) {
    return state.activeLevel;
  },
};

const actions = {
  SET_ACTIVE_STEP: ({ commit, dispatch }, payload) => {
    commit("ACTIVE_STEP", payload);
    commit("ACTIVE_TYPE", payload.activeType);
  },
  SET_ACTIVE_LEVEL: ({ commit, dispatch }, payload) => {
    commit("ACTIVE_LEVEL", payload);
  },
  SET_ACTIVE_LESSON: ({ commit, rootState }, payload) => {
    commit("ACTIVE_LESSON", payload.lesson);
    stepRepository
      .getSteps(payload.lesson.id)
      .then((result) => {
        const steps = result.data.data;

        steps.sort(function (a, b) {
          return a.sequence - b.sequence;
        });

        let nonLinearsIndexs = steps
          .map((step, i) => (step.type === "linear" ? i : -1))
          .filter((index) => index !== -1);

        const setupSteps = steps.slice(0, nonLinearsIndexs[0]);
        commit("SET_TOTAL_STEPS", steps.length - setupSteps.length);

        // create main steps

        const mainSteps = [];

        for (let i = 0; i < nonLinearsIndexs.length; i++) {
          const linearAndNonLinear = steps.slice(
            nonLinearsIndexs[i],
            nonLinearsIndexs[i + 1],
          );
          const linear = linearAndNonLinear.filter(
            (linear) => linear.type === "linear",
          );
          const children = linearAndNonLinear.filter(
            (linearAndNonLinear) => linearAndNonLinear.type === "nonLinear",
          );
          mainSteps.push({ linear: linear[0], children: children });
        }
        commit("SET_SETUP_STEPS", setupSteps);
        commit("SET_MAIN_STEPS", mainSteps);
      })
      .catch(() => {
        commit("SET_SETUP_STEPS", []);
        commit("SET_MAIN_STEPS", []);
      });
  },
  SET_TOTAL_STEPS_LOADED: ({ commit, state, dispatch }, payload) => {
    if (state.totalStepsLoaded.indexOf(payload) === -1) {
      commit("SET_TOTAL_STEPS_LOADED", payload);
    }
  },
  RESET_TOTAL_STEPS_LOADED: ({ commit }) => {
    commit("RESET_TOTAL_STEPS_LOADED");
  },
  SET_ACTIVE_ACTION: ({ commit, dispatch }, payload) => {
    if (!payload.activeType) {
      commit("ACTIVE_TYPE", null);
    } else if (payload.type === "create") {
      commit("ACTIVE_ACTION", payload);
      commit("ACTIVE_TYPE", payload.activeType);
    } else {
      if (payload.action) {
        commit("ACTIVE_ACTION", payload);
        commit("ACTIVE_TYPE", payload.activeType);
      } else {
        actionRepository
          .getSingleAction(
            state.activeLesson.id,
            payload.stepId,
            payload.id,
            payload.locale ?? localStorage.getItem("defaultLocale"),
          )
          .then((result) => {
            const action = {
              id: payload.id,
              type: payload.type,
              stepId: payload.stepId,
              sort: payload.sort,
              action: result.data.data,
            };
            commit("ACTIVE_ACTION", action);
            commit("ACTIVE_TYPE", payload.activeType);
          });
      }
    }
  },
  CREATE_STEP: ({ commit, state, dispatch }, payload) => {
    stepRepository
      .newStep(state.activeLesson.id, payload)
      .then((result) => {
        showNotification(
          i18n.global.t("notifications.create_step"),
          i18n.global.t("notifications.the_step_is_successfully_created"),
          "success",
        );
        dispatch("SET_ACTIVE_STEP", {
          id: result.data.data.id,
          kind: result.data.data.type,
          type: "edit",
          activeType: "step",
        });
        dispatch("GET_CHAPTER_TREE");
      })
      .catch(() => {});
  },

  EDIT_STEP: ({ commit, state, dispatch }, payload) => {
    stepRepository
      .editStep(state.activeLesson.id, payload, state.activeStep.id)
      .then((result) => {
        showNotification(
          i18n.global.t("notifications.edit_step"),
          i18n.global.t("notifications.the_step_is_successfully_edited"),
          "success",
        );
        dispatch("GET_CHAPTER_TREE");
      })
      .catch((err) => {
        showNotification(
          i18n.global.t("notifications.editing_went_wrong"),
          i18n.global.t(
            "notifications.something_went_wrong_while_editing_this_step",
          ),
          "error",
        );
      });
  },

  DELETE_STEP: ({ commit, state, dispatch }, payload) => {
    stepRepository
      .deleteStep(state.activeLesson.id, state.activeStep.id)
      .then((result) => {
        showNotification(
          i18n.global.t("notifications.delete_step"),
          i18n.global.t("notifications.the_step_is_successfully_deleted"),
          "success",
        );
        dispatch("SET_ACTIVE_STEP", {
          id: "",
          kind: "",
          type: "",
          activeType: "",
        });
        dispatch("GET_CHAPTER_TREE");
      })
      .catch((err) => {});
  },

  DELETE_ACTION: ({ commit, state, dispatch }, payload) => {
    actionRepository
      .deleteAction(payload.lessonId, payload.actionStepId, payload.actionId)
      .then((result) => {
        showNotification(
          i18n.global.t("notifications.action_deleted"),
          i18n.global.t("notifications.action_is_successfully_deleted"),
          "success",
        );
        dispatch("GET_CHAPTER_TREE");
      })
      .catch((err) => {
        showNotification(
          err,
          i18n.global.t(
            "notifications.something_went_wrong_deleting_this_action",
          ),
          "error",
        );
      });
  },

  GET_ACTION: ({ commit, state, dispatch }, payload) => {
    return actionRepository
      .getSingleAction(
        state.activeLesson.id,
        payload.stepId,
        payload.id,
        payload.locale,
      )
      .then((result) => {
        return result.data.data;
      })
      .catch((err) => {
        showNotification(
          err,
          i18n.global.t(
            "notifications.something_went_wrong_while_getting_this_action",
          ),
          "error",
        );
      });
  },

  GET_CHAPTER_TREE: ({ commit, state }) => {
    stepRepository.getSteps(state.activeLesson.id).then((result) => {
      const steps = result.data.data;

      steps.sort(function (a, b) {
        return a.sequence - b.sequence;
      });

      let nonLinearsIndexs = steps
        .map((step, i) => (step.type === "linear" ? i : -1))
        .filter((index) => index !== -1);

      const setupSteps = steps.slice(0, nonLinearsIndexs[0]);

      // create main steps

      const mainSteps = [];

      for (let i = 0; i < nonLinearsIndexs.length; i++) {
        const linearAndNonLinear = steps.slice(
          nonLinearsIndexs[i],
          nonLinearsIndexs[i + 1],
        );
        const linear = linearAndNonLinear.filter(
          (linear) => linear.type === "linear",
        );
        const children = linearAndNonLinear.filter(
          (linearAndNonLinear) => linearAndNonLinear.type === "nonLinear",
        );
        mainSteps.push({ linear: linear[0], children: children });
      }
      commit("SET_SETUP_STEPS", setupSteps);
      commit("SET_MAIN_STEPS", mainSteps);
    });
  },
  UPDATE_ACTION: ({ commit, state }, payload) => {
    actionRepository
      .updateAction(
        payload.lessonId,
        payload.stepId,
        payload.id,
        payload.action,
      )
      .then((result) => {
        commit("UPDATE_ACTION", result.data.data);
      })
      .catch((err) => {
        showNotification(
          i18n.global.t(
            "notifications.something_went_wrong_while_activating_deactivating",
          ),
          "error",
        );
      });
  },
  SET_ACTION: ({ commit, state }, payload) => {
    commit("UPDATE_ACTION", payload);
  },
};

const mutations = {
  ACTIVE_STEP: (state, payload) => {
    state.activeStep = payload;
  },
  ACTIVE_TYPE: (state, payload) => {
    state.activeType = payload;
  },
  ACTIVE_LEVEL: (state, payload) => {
    state.activeLevel = payload;
  },
  ACTIVE_LESSON: (state, payload) => {
    state.activeLesson = payload;
  },
  ACTIVE_ACTION: (state, payload) => {
    state.activeAction = payload;
  },
  SET_SETUP_STEPS: (state, payload) => {
    state.setupSteps = payload;
  },
  SET_MAIN_STEPS: (state, payload) => {
    state.mainSteps = payload;
  },
  SET_TOTAL_STEPS: (state, payload) => {
    state.totalSteps = payload;
  },
  SET_TOTAL_STEPS_LOADED: (state, payload) => {
    state.totalStepsLoaded.push(payload);
  },
  RESET_TOTAL_STEPS_LOADED: (state) => {
    state.totalStepsLoaded = [];
    state.totalSteps = 0;
  },
  UPDATE_ACTION: (state, payload) => {
    state.activeAction = {
      ...state.activeAction,
      action: payload,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
