import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import * as Sentry from "@sentry/vue";
import store from "./store/store";
import { sentryDSN } from "./config";
import { defineRule } from "vee-validate";
import * as AllRules from "@vee-validate/rules";
import "../src/helpers/customValidationRules";
import PrimeVue from "primevue/config";
import "./assets/css/prime.css";
import "primevue/resources/primevue.min.css";

const app = createApp(App);
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

app.use(PrimeVue);
app.component("VSelect", vSelect);

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
import vClickOutside from "click-outside-vue3";
app.use(vClickOutside);

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";

app.use(VCalendar, {
  masks: {
    title: "MMMM YYYY",
    weekdays: "WW",
    navMonths: "MMM",
    input: ["DD-MM-YYYY", "D-M-YYYY", "DD-MM-YYYY", "DD/MM/YYYY"],
    dayPopover: "L",
    data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
  },
});

import VueObserveVisibility from "vue-observe-visibility";
app.use(VueObserveVisibility);

import { VTooltip, VClosePopper, Dropdown, Tooltip, Menu } from "floating-vue";
import "floating-vue/dist/style.css";

app.directive("tooltip", VTooltip);
app.directive("close-popper", VClosePopper);

app.component("VDropdown", Dropdown);
app.component("VTooltip", Tooltip);
app.component("VMenu", Menu);

Sentry.init({
  app,
  dsn: sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 0.0,
  denyUrls: "localhost",
  release: require("../VERSION.json").version,
});

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

app.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ["ended"],
});

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import library from "./icons";

const ValidationObserver = () => "";
const ValidationProvider = () => "";
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("FontAwesomeLayers", FontAwesomeLayers);
app.component("ValidationObserver", ValidationObserver);
app.component("ValidationProvider", ValidationProvider);

import { Form, configure } from "vee-validate";
app.component("Form", Form);

import { localize, setLocale } from "@vee-validate/i18n";
import en from "@vee-validate/i18n/dist/locale/en.json";
import nl from "@vee-validate/i18n/dist/locale/nl.json";
configure({
  generateMessage: (ctx) => {
    const localizeFn = localize({
      en,
      nl,
    });
    return localizeFn({ ...ctx, name: ctx.field });
  },
});
const locale = localStorage.locale || navigator.language.split("-")[0] || "en";
setLocale(locale);

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/nl";
dayjs.locale(locale);
dayjs.extend(utc).extend(timezone).extend(relativeTime);
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
app.config.globalProperties.$dayjs = dayjs;

// app.use(VueRouterBackButton, { router });
app.use(store);
app.use(router);
app.use(i18n);

app.mount("#app");
