<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
          <div class="flex justify-end">
            <Button
              :title="$t('trophies.add_trophy')"
              @clickAction="createTrophy"
              button-type="editor-primary"
              class="mb-4"
            />
          </div>
          <div
            class="-mt-2 mb-8 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg"
            >
              <div
                v-if="loading"
                class="py-48 flex items-center justify-center"
              >
                <Loader />
              </div>
              <table
                v-else
                class="min-w-full bg-gray-800"
                data-cy="dealersTable"
              >
                <thead class="bg-primaryColor">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("general.name") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("editor.description") }}
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <TrophyRow
                    v-for="trophy in trophies"
                    :key="trophy.id"
                    :id="trophy.id"
                    :name="trophy.name"
                    :description="trophy.description"
                    @edit="editTrophy(trophy)"
                    @deleted="getTrophies"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TrophyModal v-if="showModal" :edit="edit" @close="close()" />
  </PageContentContainer>
</template>

<script>
import { mapState } from "vuex";
import PageContentContainer from "../../components/helpers/PageContentContainer";
import Loader from "../../components/helpers/Loader";
import TrophyRow from "@/views/editor/components/TrophyRow";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import TrophyModal from "@/views/editor/components/modals/TrophyModal";
import Button from "@/components/form/Button";

export default {
  name: "Trophies",
  components: { PageContentContainer, Loader, TrophyRow, TrophyModal, Button },
  data() {
    return {
      loading: false,
      trophies: [],
      edit: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versions"]),
  },
  mounted() {
    this.getTrophies();
  },
  methods: {
    editTrophy(trophy) {
      this.showModal = true;
      this.edit = trophy;
    },
    createTrophy() {
      this.showModal = true;
      this.edit = null;
    },
    close() {
      this.showModal = false;
      this.getTrophies();
    },
    getTrophies() {
      this.loading = true;
      trainingRepository
        .getTrophies(this.trainingId)
        .then((result) => {
          this.trophies = result.data.data;
          this.loading = false;
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
