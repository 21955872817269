<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-550 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div id="right" class="flex flex-1 flex-col bg-standardBgColor">
        <div class="flex flex-2 flex-row w-full justify-center items-center">
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.login").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div
          id="form-container"
          class="flex flex-9 mobile:flex-6 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <div class="w-full">
            <Form @keyup.enter="login" @submit="login">
              <InputField
                class="mt-inputPadding w-full"
                :title="$t('auth.email')"
                rules="required|email"
                :required-input="true"
                type="email"
                placeholder=""
                v-model="username"
                @update:modelValue="clearError"
              />

              <InputField
                class="mt-inputPadding w-full"
                :title="$t('auth.password')"
                rules="required"
                :required-input="true"
                type="password"
                placeholder=""
                v-model="password"
                @update:modelValue="clearError"
              />

              <div class="flex cursor-pointer justify-end w-full max-w-400">
                <p
                  class="font-light text-13 text-darkAlineaColor"
                  @click="toResetPassword()"
                >
                  {{ $t("auth.forgot_password") }}
                </p>
              </div>

              <div class="mt-6">
                <!--              <Button-->
                <!--                type="submit"-->
                <!--                class="mb-2"-->
                <!--                :title="$t('auth.login')"-->
                <!--                width="full"-->
                <!--                button-type="primary"-->
                <!--                :invalid="invalid"-->
                <!--                :loading="loading"-->
                <!--              />-->
                <Button
                  type="submit"
                  class="mb-2"
                  :title="$t('auth.login')"
                  width="full"
                  button-type="primary"
                  :loading="loading"
                />
                <Button
                  :title="$t('auth.register_account')"
                  button-type="secondary"
                  width="full"
                  @clickAction="$router.push({ name: 'register' })"
                />
              </div>
              <ErrorMessage class="mt-2" :error-message="responseError" />
            </Form>
          </div>
        </div>
      </div>
    </div>
    <RedirectMessageModal
      v-if="$route.query.legacy_redirect === 'true' && redirectMessage"
      @cancel="redirectMessage = false"
    />
  </div>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import LeftContainer from "@/components/authentication/LeftContainer";
import RedirectMessageModal from "@/components/helpers/modals/RedirectMessageModal";
import ErrorMessage from "@/components/helpers/ErrorMessage";

export default {
  name: "Login",
  components: {
    InputField,
    Button,
    LeftContainer,
    RedirectMessageModal,
    ErrorMessage,
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      redirectMessage: true,
      responseError: "",
    };
  },
  methods: {
    login() {
      const { username, password, invalid } = this;
      if (username && password) {
        this.loading = true;
        this.$store
          .dispatch("authStore/AUTH_REQUEST", { username, password })
          .then(() => {
            this.loading = false;
            this.$store.dispatch("translationStore/GET_LOCALES").then(() => {
              this.$router.push("/");
            });
          })
          .catch(() => {
            this.responseError = this.$t("auth.invalid_credentials");
            this.loading = false;
            this.password = "";
          });
      }
    },
    toResetPassword() {
      this.$router.push({ name: "forgot-password" });
    },
    clearError() {
      this.responseError = "";
    },
  },
};
</script>
