<template>
  <tr
    @click="action"
    class="hover:bg-rowHover focus:bg-gray-100 cursor-pointer"
  >
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex flex-row items-center">
        <div class="flex justify-start items-center mr-3">
          <ProfileImage
            class="desktop:hidden"
            :image="user.image"
            :firstname="user.firstname"
            :lastname="user.lastname"
            size="w-30 h-30"
          />
          <ProfileImage
            class="mobile:hidden"
            :image="user.image"
            :firstname="user.firstname"
            :lastname="user.lastname"
            size="w-10 h-10"
          />
        </div>

        <div class="flex flex-1 text-sm leading-5 font-medium text-gray-100">
          {{ `${user.firstname} ${user.lastname}` }}
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      <div class="flex items-center">
        <div class="inline-flex">
          <div
            v-tooltip="{
              content: `<div class='flex flex-col z-100'>
                    <span>${$t('users.created_at_date', {
                      date: getStartDate,
                    })}</span>
                  </div>`,
              boundary: 'window',
              html: true,
            }"
          >
            <font-awesome-icon
              :icon="user.active ? 'check' : 'times'"
              class="fa-md"
              :class="{
                'text-green-500': user.active,
                'text-red-500': !user.active,
              }"
            />
          </div>
          <div
            v-if="customerOrDealerInactive"
            class="ml-2"
            v-tooltip="{
              content: `<div class='flex flex-col z-100'>
                  <span>${getUserInactiveMessage}</span>
                </div>`,
              boundary: 'window',
              html: true,
            }"
          >
            <font-awesome-icon
              :icon="'exclamation-triangle'"
              class="fa-md text-yellow-500"
            />
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ user.email }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      <VTooltip
        :id="`last-played-${user.id}`"
        :offset="[0, 8]"
        :triggers="['hover']"
        placement="top"
        boundary="window"
      >
        <div>
          {{ user.last_time_played ? `${getDays} ${$t("general.ago")}` : "" }}
        </div>
        <template #popper>
          <div class="flex flex-col">
            <span>
              {{ $t("users.last_time_played") }}: {{ getDate }} {{ getTime }}
            </span>
            <span>
              {{ $t("users.last_version_played") }}:
              {{ user.last_version_played }}
            </span>
          </div>
        </template>
      </VTooltip>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      <span v-if="user.customer">{{ user.customer.data.name }}</span>
      <span v-else-if="user.dealer">{{ user.dealer.data.name }}</span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      <div v-if="allowEdit">
        <Button
          button-type="transparent"
          icon="edit"
          hover="default"
          class="mr-1"
          @clickAction="$emit('edit')"
        />
        <Button
          v-if="currentUser.id !== user.id"
          icon="trash"
          size="m"
          hover="red"
          button-type="trashcan"
          @clickAction="$emit('delete')"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { nl, enGB } from "date-fns/locale";
import { formatDistance, format } from "date-fns";
import Button from "@/components/form/Button";
import ProfileImage from "@/components/profile/ProfileImage";
import { mapState } from "vuex";
import { allowEdit } from "@/services/roleService";

export default {
  name: "TableRow",
  components: {
    Button,
    ProfileImage,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    getDays: function () {
      return formatDistance(new Date(this.user.last_time_played), new Date(), {
        locale: this.getLanguage,
      });
    },
    getDate: function () {
      return format(new Date(this.user.last_time_played), "dd-MM-yyyy");
    },
    customerOrDealerInactive: function () {
      return !!(
        (this.user.customer && !this.user.customer.data.enabled) ||
        (this.user.dealer && !this.user.dealer.data.enabled)
      );
    },
    getUserInactiveMessage: function () {
      if (this.user.customer && !this.user.customer.data.enabled) {
        return (
          this.$t("users.customer") +
          " " +
          this.$t("users.inactive").toLowerCase()
        );
      }
      if (this.user.dealer && !this.user.dealer.data.enabled) {
        return (
          this.$t("users.dealer") +
          " " +
          this.$t("users.inactive").toLowerCase()
        );
      }
      return "";
    },
    getStartDate: function () {
      return format(new Date(this.user.created_at), "dd-MM-yyyy");
    },
    getTime: function () {
      const getDate = new Date(this.user.last_time_played);

      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }

      const hours = addZero(getDate.getHours());
      const minutes = addZero(getDate.getMinutes());

      return `${hours}:${minutes}`;
    },
    getLanguage: function () {
      switch (this.$i18n.locale) {
        case "nl":
          return nl;
        case "en":
          return enGB;
        default:
          return enGB;
      }
    },
    allowEdit: function () {
      return allowEdit(
        this.user.roles_reachable,
        this.currentUser.roles_reachable,
      );
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "usersDetail",
        params: { id: this.user.id },
      });
    },
  },
};
</script>

<style></style>
